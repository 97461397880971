/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Alert, Stack, styled} from '@mui/material';
import {FC} from 'react';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
interface Props {
  canPlay?: boolean;
  audioURL?: string;
}

const StyledAudioPlayer = styled(AudioPlayer)(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : theme.palette.grey[100],
  borderRadius: '24px',
  boxShadow: 'none',
  width: '100%',
  '.rhap_time': {
    color: theme.palette.text.primary,
  },
  '.rhap_main-controls-button': {
    color: theme.palette.text.primary,
  },
  '.rhap_volume-button': {
    color: theme.palette.text.primary,
  },
  '.rhap_volume-bar': {
    backgroundColor: theme.palette.text.primary,
  },
  '.rhap_button-clear': {
    color: theme.palette.text.primary,
  },
  '.rhap_main-controls': {
    justifyContent: 'end',
    flex: 1,
  },
}));

const ContactAudioPlayer: FC<Props> = ({canPlay, audioURL}) => {
  return (
    <Stack spacing={2}>
      {canPlay && (
        <Alert severity="warning">
          No audio is available for this contact.
        </Alert>
      )}
      <StyledAudioPlayer
        src={audioURL}
        autoPlay={false}
        layout="stacked"
        progressJumpSteps={{backward: 15000, forward: 15000}}
        customControlsSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
        customProgressBarSection={[
          RHAP_UI.CURRENT_TIME,
          <div key="divider">/</div>,
          RHAP_UI.DURATION,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.VOLUME,
        ]}
        customVolumeControls={[]}
      />
    </Stack>
  );
};

export default ContactAudioPlayer;
