/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactSynopsisElement,
  GetBatchContactImportResponseContent,
  useCreatePromptRevision,
  useGetAudioPresignedURL,
  useGetBatchContactImport,
  useGetContact,
  useGetContactMetadata,
  useGetPromptRevision,
  useListTranscripts,
  usePublishPromptRevision,
  useReTryContactAction,
  useTestPromptForContact,
} from '@agent-assist/api-typescript-react-query-hooks';
import {PartialTranscript} from '@agent-assist/common';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import ExploreIcon from '@mui/icons-material/Explore';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Link,
  Stack,
  Tab,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {format} from 'date-fns';
import humanizeDuration from 'humanize-duration';
import orderBy from 'lodash/orderBy';
import {Clock, Info} from 'lucide-react';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {TranscriptBubble} from './contact/transcript/transcript-bubble';
import {mapResults} from './contact-import/ViewListComplete';
import {ErrorPage} from './error/error-page';
import ContactAudioPlayer from '../components/AudioPlayer';
import {ContactSourceIndicator} from '../components/contact/contact-source-indicator';
import {ContactSummaryContainer} from '../components/contact/contact-summary-container';
import LegacyHeading from '../components/Heading';
import {LoadingSpinner} from '../components/LoadingSpinner';
import PromptExplorerDrawer from '../components/PromptExplorer/PromptExplorerDrawer';
import {ScreenLoader} from '../components/ScreenLoader';
import StatusIndicator, {StatusTypeEnum} from '../components/StatusIndicator';
import {PageContainer} from '../components/ui/page';
import {getColorFromPercentage} from '../lib/getColorFromPercentage';
import {prettyTitleString} from '../lib/utils';
import {setAudioUrl, usePromptDispatch} from '../providers/PromptProvider';

import './contact/view-contact.css';

type ContactTimes = {startedAt: string; completedAt: string};

type ScoreKey =
  | 'complianceTotalScore'
  | 'softSkillsTotalScore'
  | 'productTotalScore'
  | 'processTotalScore';

type ViewContactParams = {
  contactId?: string;
  contactImportId?: string;
};
type ViewContactProps = {
  contactId?: string;
  contactImportId?: string;
  calibrationMode?: boolean;
  onCloseCalibrationModal?: () => void;
};
export const ContactViewPage: FC<ViewContactProps> = ({
  contactId: contactIdProp,
  contactImportId: contactImportIdProp,
  calibrationMode,
  onCloseCalibrationModal,
}) => {
  const [localAudioUrl, setLocalAudioUrl] = useState<string | undefined>(
    undefined,
  );
  const dispatch = usePromptDispatch();
  const {contactId: contactIdParam, contactImportId: contactImportIdParam} =
    useParams<ViewContactParams>();
  const contactId = contactIdProp || contactIdParam;
  const contactImportId = contactImportIdProp || contactImportIdParam;
  const [showPromptExplorer, setShowPromptExplorer] = useState(false);
  const [showPromptFeedback, setShowPromptFeedback] = useState(false);

  if (!contactId || !contactImportId)
    throw new Error('contactId & contactImportId is required');

  const [sortBy] = useState<'endOffset' | 'startOffset'>('endOffset');
  const contact = useGetContact({contactId, contactImportId});
  const contactMetaApi = useGetContactMetadata({contactId, contactImportId});
  const transcripts = useListTranscripts({
    contactId,
    contactImportId,
    pageSize: 10,
  });
  const transcriptScrollContainer = useRef<HTMLDivElement>(null);
  const testPrompt = useTestPromptForContact();
  const createPrompt = useCreatePromptRevision();
  const publishPrompt = usePublishPromptRevision();
  const retryAction = useReTryContactAction();
  const parentImport = contactImportId
    ? useGetBatchContactImport({contactImportId})
    : useGetContact({contactId});
  const getPresignedUrl = contactImportId
    ? useGetAudioPresignedURL({
        contactId,
        contactImportId,
      })
    : null;
  const isCompleted = contact.data?.status === 'COMPLETED';
  const [tabAValue, setTabAValue] = useState<number>(0);

  const handleTabAChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabAValue(newValue);
  };
  const [tabBValue, setTabBValue] = useState('synopsis');
  const handleTabBChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabBValue(newValue);
  };
  const hasAudio = contact.data?.audioS3Key || localAudioUrl;
  useEffect(() => {
    if (getPresignedUrl) {
      setAudioUrl(dispatch, getPresignedUrl.data?.url);
      setLocalAudioUrl(getPresignedUrl.data?.url);
    }
  }, [getPresignedUrl?.data?.url]);

  const productionSummary = contact.data?.summary?.production;
  const inferredPromptRevision = useGetPromptRevision(
    {
      promptRevisionId: parentImport.data?.promptRevisionId ?? 'n/a',
    },
    {
      enabled:
        productionSummary?.status === 'SUCCESS' &&
        !!productionSummary?.promptRevisionId,
    },
  );

  const [transcriptPanelHeight, setTranscriptPanelHeight] = useState(0);
  const transcriptPanelRef = useRef<HTMLDivElement>(null);

  const updateHeight = useCallback(() => {
    if (transcriptPanelRef.current) {
      const transcriptPanelTop =
        transcriptPanelRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const availableHeight = windowHeight - transcriptPanelTop - 3 * 16;
      setTranscriptPanelHeight(availableHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    updateHeight();
    return () => window.removeEventListener('resize', updateHeight);
  }, [updateHeight]);

  useEffect(() => {
    updateHeight();
  }, [contact]);

  const allTranscripts: PartialTranscript[] = useMemo(() => {
    const existingTranscripts =
      transcripts.data?.pages?.flatMap?.((p) => p.transcripts) ?? [];
    return orderBy(existingTranscripts, sortBy);
  }, [transcripts.data, sortBy]);

  const [contactMeta, setContactMeta] = useState<ContactTimes | undefined>(
    undefined,
  );

  useEffect(() => {
    if (contactMetaApi.data?.result) {
      setContactMeta(
        contactMetaApi.data.result
          .map(mapResults)
          .at(0) as unknown as ContactTimes, // meta data has all sorts of unknown keys
      );
    }
  }, [contactImportId, contactId, contactMetaApi.data]);

  const averageScore = useMemo(() => {
    return contact.data?.summary?.qa
      ? Object.keys(contact.data.summary.qa)
          .filter((key) => key.endsWith('TotalScore'))
          .reduce(
            (sum, key, _, array) =>
              sum +
              (contact.data?.summary?.qa?.[key as ScoreKey] || 0) /
                array.length,
            0,
          )
      : null;
  }, [contact.data?.summary?.qa]);

  // ----------------------------------------------------------------------

  if (contact.isError || transcripts.isError) {
    return <ErrorPage errors={[contact.error, transcripts.error]} />;
  }

  if (contact.isLoading || !contact.data) {
    return <ScreenLoader card={!calibrationMode} />;
  }

  const canPlayAudio =
    contact.data?.status === 'IN_PROGRESS' ||
    !contact.data.s3Location ||
    !hasAudio;

  const allItems = [
    ...(contact.data.summary?.production?.synopsis || []),
    ...(contact.data.summary?.qa?.synopsis || []),
  ];

  const grouped = allItems.reduce(
    (acc, item) => {
      if (item.promptGroupName) {
        const groupName = item.promptGroupName;
        (acc[groupName] = acc[groupName] || []).push(item);
      }
      return acc;
    },
    {} as {[groupName: string]: ContactSynopsisElement[]},
  );
  const groupTabs = Object.keys(grouped);

  const renderPromptExplorer =
    !calibrationMode &&
    showPromptExplorer &&
    contact.data &&
    inferredPromptRevision.data;

  const actions =
    calibrationMode || !isCompleted ? null : (
      <Stack direction={'row'} spacing={1}>
        <Tooltip title={`Toggle feedback buttons`} arrow placement={'top'}>
          <IconButton
            onClick={() => setShowPromptFeedback(!showPromptFeedback)}
          >
            <ThumbsUpDownIcon
              color={showPromptFeedback ? 'primary' : 'secondary'}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Toggle Prompt Explorer`} arrow placement={'top'}>
          <IconButton
            onClick={() => setShowPromptExplorer(!showPromptExplorer)}
          >
            <ExploreIcon color={showPromptExplorer ? 'primary' : 'secondary'} />
          </IconButton>
        </Tooltip>
      </Stack>
    );

  return (
    <PageContainer
      title={
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography
            variant="h4"
            sx={{'&::first-letter': {textTransform: 'capitalize'}}}
          >
            Contact
          </Typography>
          {isCompleted && (
            <Chip
              color={'secondary'}
              label={averageScore ? Math.round(averageScore) + '%' : undefined}
              sx={{
                fontWeight: 'bold',
                backgroundColor: getColorFromPercentage(100),
              }}
              icon={<BarChartIcon />}
            />
          )}
          {!isCompleted && (
            <StatusIndicator status={contact.data.status as StatusTypeEnum} />
          )}
        </Stack>
      }
      subtitle={contactId}
      actions={actions}
      compact
    >
      {!!onCloseCalibrationModal && (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 'calc(2 * var(--mui-spacing))',
            right: 'calc(2 * var(--mui-spacing))',
            zIndex: 10000,
          }}
          size={'large'}
          onClick={onCloseCalibrationModal}
        >
          <CloseIcon />
        </IconButton>
      )}
      {isCompleted && (
        <Card>
          <CardContent>
            <ContactAudioPlayer
              canPlay={canPlayAudio}
              audioURL={localAudioUrl}
            />
          </CardContent>
        </Card>
      )}
      <Grid
        container
        columns={2}
        spacing={4}
        sx={{
          marginBottom: 'calc(var(--Content-paddingX) * 2 * -1)',
        }}
      >
        <Grid size={1}>
          <TabContext value={tabAValue}>
            <TabList onChange={handleTabAChange} sx={{pb: 2}} centered>
              {isCompleted && <Tab label={'Transcript'} value={0} />}
              <Tab label={'Details'} value={isCompleted ? 1 : 0} />
            </TabList>
            <Box
              ref={transcriptPanelRef}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: transcriptPanelHeight,
                overflowY: 'auto',
                pr: 1,
                pb: 2,
              }}
            >
              {isCompleted && (
                <TabPanel value={0} sx={{p: 0.25}}>
                  <Card>
                    <CardContent ref={transcriptScrollContainer}>
                      <InfiniteScroll
                        pageStart={0}
                        useWindow={false}
                        initialLoad={true}
                        threshold={100}
                        loadMore={() => {
                          if (!transcripts.isFetchingNextPage) {
                            void transcripts.fetchNextPage();
                          }
                        }}
                        hasMore={transcripts.hasNextPage}
                        loader={
                          <div
                            key="view-contact-infinite-scroll-loader"
                            className={
                              'flex w-full items-center justify-center my-2'
                            }
                          >
                            <LoadingSpinner size={'sm'} />
                          </div>
                        }
                      >
                        <div className={'flex flex-1 flex-col'}>
                          {allTranscripts.map((t, i) => (
                            <TranscriptBubble
                              key={t.transcriptId}
                              startedAt={contact.data.startedAt}
                              transcript={t}
                              prev={allTranscripts[i - 1]}
                              next={allTranscripts[i + 1]}
                            />
                          ))}
                        </div>
                      </InfiniteScroll>
                    </CardContent>
                  </Card>
                </TabPanel>
              )}
              <TabPanel value={isCompleted ? 1 : 0} sx={{p: 0.25}}>
                <Grid
                  container
                  spacing={4}
                  columns={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                  }}
                >
                  <Grid size={1} sx={{display: 'flex'}}>
                    <Card sx={{flex: 1}}>
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <LegacyHeading h3>Prompt:</LegacyHeading>
                            <Link
                              to={`/prompts/${productionSummary?.promptRevisionId}`}
                              component={RouterLink}
                            >
                              {inferredPromptRevision.data?.name ??
                                productionSummary?.promptRevisionId}
                            </Link>
                          </Stack>
                          <Stack spacing={1}>
                            <LegacyHeading h3>Source:</LegacyHeading>
                            <ContactSourceIndicator
                              source={contact.data.source}
                              contactImport={
                                contactImportId
                                  ? (parentImport.data as GetBatchContactImportResponseContent)
                                  : undefined
                              }
                            />
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid size={1} sx={{display: 'flex'}}>
                    <Card sx={{flex: 1}}>
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <LegacyHeading h3>Status:</LegacyHeading>
                            <StatusIndicator
                              status={
                                retryAction.isLoading
                                  ? ('IN_PROGRESS' as StatusTypeEnum)
                                  : (contact.data.status as StatusTypeEnum)
                              }
                            />
                          </Stack>
                          <Stack spacing={1}>
                            <LegacyHeading h3>Duration:</LegacyHeading>
                            <p>
                              {contact.data.completedAt
                                ? humanizeDuration(
                                    contact.data.completedAt -
                                      contact.data.startedAt,
                                    {largest: 2, round: true},
                                  )
                                : undefined}
                            </p>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid size={1} sx={{display: 'flex'}}>
                    <Card sx={{flex: 1}}>
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <DisplayTime
                              isLoading={contactMetaApi.isLoading}
                              time={contactMeta?.startedAt}
                              title="Started at"
                            />
                          </Stack>
                          <Stack spacing={1}>
                            <DisplayTime
                              isLoading={contactMetaApi.isLoading}
                              time={contactMeta?.completedAt}
                              title="Finished at"
                            />
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </TabContext>
        </Grid>
        <Grid
          size={1}
          sx={{
            '.MuiTabs-root': {
              overflow: 'visible',
            },
            '.MuiTabs-fixed': {
              overflow: 'visible !important',
            },
            '.MuiTab-root': {
              overflow: 'visible',
              paddingInline: 1,
            },
          }}
        >
          <TabContext value={tabBValue}>
            <TabList
              onChange={handleTabBChange}
              sx={{
                pb: 2,
              }}
              centered
            >
              {groupTabs.map((groupName) => {
                const totalScoreKey = `${groupName}TotalScore`;
                const totalScore = contact.data.summary?.qa
                  ? contact.data.summary.qa[totalScoreKey as ScoreKey]
                  : null;
                return (
                  <Tab
                    label={
                      totalScore ? (
                        <Badge
                          color={'secondary'}
                          badgeContent={`${Math.round(totalScore)}%`}
                          max={100}
                          slotProps={{
                            badge: {
                              // @ts-ignore TS not liking this but it's valid!
                              sx: {
                                marginTop: 'calc(-0.75 * var(--mui-spacing))',
                                backgroundColor:
                                  getColorFromPercentage(totalScore),
                              },
                            },
                          }}
                        >
                          <Typography variant={'body2'}>
                            {prettyTitleString(groupName)}
                          </Typography>
                        </Badge>
                      ) : (
                        prettyTitleString(groupName)
                      )
                    }
                    value={groupName}
                    key={groupName}
                  />
                );
              })}
            </TabList>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: transcriptPanelHeight,
                overflowY: 'auto',
                pr: 1,
                pb: 2,
              }}
            >
              {groupTabs.map((groupName) => {
                return (
                  <TabPanel value={groupName} sx={{p: 0.25}} key={groupName}>
                    <ContactSummaryContainer
                      elements={grouped[groupName]}
                      showFeedback={
                        (calibrationMode || showPromptFeedback) &&
                        groupName !== 'synopsis'
                      }
                      contactImportId={contactImportId}
                      contactId={contactId}
                    />
                  </TabPanel>
                );
              })}
            </Box>
          </TabContext>
        </Grid>
      </Grid>
      {renderPromptExplorer && (
        <>
          <PromptExplorerDrawer
            contactData={contact.data}
            inferredPromptRevision={inferredPromptRevision}
            contactImportId={contactImportId}
            contactId={contactId}
            testPrompt={testPrompt}
            publishPrompt={publishPrompt}
            createPrompt={createPrompt}
          />
          <Box height={1} />
        </>
      )}
    </PageContainer>
  );
};

interface DisplayTimeProps {
  title: string;
  time?: string;
  isLoading: boolean;
}

const DisplayTime = ({title, time, isLoading}: DisplayTimeProps) => {
  return (
    <>
      <LegacyHeading h3>{title}:</LegacyHeading>
      <p className="flex items-center">
        {isLoading ? (
          <LoadingSpinner size={'xs'} />
        ) : time ? (
          format(new Date(time), 'd MMM yy, h:mm aaa')
        ) : (
          <Tooltip title={`No data was found for ${title}`}>
            <div className="relative w-7">
              <Clock className="text-yellow-500" />
              <Info
                size={12}
                className="bg-accent absolute top-0 right-0 rounded-full"
              />
            </div>
          </Tooltip>
        )}
      </p>
    </>
  );
};

export default ContactViewPage;
