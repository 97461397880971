const hexToRgb = (hex: string): number[] => {
  const cleanHex = hex.replace('#', '');
  const bigint = parseInt(cleanHex, 16);
  // eslint-disable-next-line no-bitwise
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

const getColorFromCSSVariable = (variable: string): number[] | null => {
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
  if (!value || !/^#([A-Fa-f0-9]{6})$/.test(value)) {
    return null;
  }
  return hexToRgb(value);
};

export const getColorFromPercentage = (percentage: number): string | null => {
  const clampedPercentage = Math.max(0, Math.min(100, percentage));

  const errorColor = getColorFromCSSVariable('--mui-palette-error-main');
  const warningColor = getColorFromCSSVariable('--mui-palette-warning-main');
  const successColor = getColorFromCSSVariable('--mui-palette-success-main');

  if (!errorColor || !warningColor || !successColor) {
    return null;
  }

  let startColor: number[];
  let endColor: number[];
  let weight: number;

  if (clampedPercentage <= 50) {
    startColor = errorColor;
    endColor = warningColor;
    weight = clampedPercentage / 50;
  } else {
    startColor = warningColor;
    endColor = successColor;
    weight = (clampedPercentage - 50) / 50;
  }

  const interpolatedColor = startColor.map((start, i) =>
    Math.round(start + (endColor[i] - start) * weight),
  );

  return `#${interpolatedColor.map((val) => val.toString(16).padStart(2, '0')).join('')}`;
};
