/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  type ContactSynopsisElement,
  SynopsisEvaluationRating,
  useUpdatePromptFeedback,
} from '@agent-assist/api-typescript-react-query-hooks';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import StarIcon from '@mui/icons-material/Star';
import {Box, Card, CardContent, Chip, Stack} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {ReactNode, useCallback, useState} from 'react';
import {prettyTitleString} from '../../lib/utils';
import {PromptRating} from '../QAAnalytics/QAExpandableDataGrid-datatables';

const MAX_LENGTH_SINGLE_COLUMN = 40;

type Props = {
  elements: ContactSynopsisElement[];
  contactId: string;
  contactImportId: string;
  showFeedback?: boolean;
};
export const ContactSummaryContainer = ({
  elements,
  showFeedback,
  contactId,
  contactImportId,
}: Props) => {
  const feedbackOnPrompt = useUpdatePromptFeedback();
  const [isAwaitingFeedback, setIsAwaitingFeedback] = useState(false);
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 2,
      }}
    >
      {elements.map((element) => {
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const handleTooltipClose = () => {
          setTooltipOpen(false);
        };
        const handleTooltipOpen = () => {
          setTooltipOpen(true);
        };
        const isWide =
          element.response.toString().length > MAX_LENGTH_SINGLE_COLUMN;

        const onFeedback = useCallback(
          async (questionId: string, rating: SynopsisEvaluationRating) => {
            try {
              setIsAwaitingFeedback(true);
              await feedbackOnPrompt.mutateAsync({
                contactId,
                contactImportId,
                updatePromptFeedbackRequestContent: {
                  rating,
                  questionId,
                },
              });
            } catch (e) {
              console.error(e);
            } finally {
              setIsAwaitingFeedback(false);
            }
          },
          [],
        );
        const onSaveComment = useCallback(
          async (questionId: string, commentToSave: string) => {
            if (commentToSave === '') {
              return;
            }
            await feedbackOnPrompt.mutateAsync({
              contactId,
              contactImportId,
              updatePromptFeedbackRequestContent: {
                comment: commentToSave,
                questionId,
              },
            });
          },
          [],
        );

        let response: ReactNode;
        switch (element?.question?.promptType) {
          case 'extraction_binary':
            response = (
              <Stack direction={'row'} spacing={1}>
                <Chip
                  color={element.response ? 'success' : 'error'}
                  label={element.response ? 'Yes' : 'No'}
                  variant="soft"
                  icon={element.response ? <CheckIcon /> : <CloseIcon />}
                />
                {!!element.score && (
                  <Chip
                    color={'secondary'}
                    label={element.score}
                    variant="soft"
                    sx={{fontWeight: 'bold'}}
                    icon={<BarChartIcon />}
                  />
                )}
              </Stack>
            );
            break;
          case 'extraction_single_label':
            response = (
              <Chip
                color={'secondary'}
                label={element.response}
                variant="soft"
              />
            );
            break;
          case 'extraction_rating':
            response = (
              <Chip
                color={'secondary'}
                label={element.response}
                variant="soft"
                icon={<StarIcon />}
                sx={{fontWeight: 'bold'}}
              />
            );
            break;
          case 'synthesis':
          default:
            response = <Typography>{element.response}</Typography>;
            break;
        }

        return (
          <Card
            key={element.key}
            sx={{
              gridColumn: isWide ? 'span 2' : 'span 1',
              width: '100%',
            }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{marginTop: 'calc(-1.25 * var(--mui-spacing))'}}
                >
                  <Typography variant="h6">
                    {prettyTitleString(element.key)}
                  </Typography>
                  <Stack direction={'row'} spacing={0.5}>
                    {showFeedback && element?.question && (
                      <PromptRating
                        promptRating={element.rating?.rating}
                        promptComment={element.comment?.comment}
                        onFeedback={onFeedback}
                        onSaveComment={onSaveComment}
                        questionId={element?.question.id}
                        disabled={isAwaitingFeedback}
                      />
                    )}
                    {element?.question?.question && (
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                          onClose={handleTooltipClose}
                          open={tooltipOpen}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={
                            <Typography variant={'body2'}>
                              {element.question.question}
                            </Typography>
                          }
                        >
                          <IconButton
                            size="small"
                            onClick={
                              tooltipOpen
                                ? handleTooltipClose
                                : handleTooltipOpen
                            }
                            sx={{mr: -1}}
                          >
                            <HelpCenterOutlinedIcon fontSize={'small'} />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    )}
                  </Stack>
                </Stack>
                <Box>{response}</Box>
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};
