/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {ReactNode, useCallback, useState} from 'react';

import {Dialog, DialogFooter} from '../../components/ui/dialog';
import {Input} from '../../components/ui/input';
import {Label} from '../../components/ui/label';
import {LegacyButton} from '../../components/ui/legacy-button';

interface PromptCommentModalProps {
  readonly onConfirm: () => Promise<void>;
  readonly comment: string;
  readonly onChangeComment: (comment: string) => void;
}

interface PromptCommentModal {
  readonly modal: ReactNode;
  readonly show: () => void;
  readonly hide: () => void;
}

export const usePromptCommentModal = ({
  onConfirm,
  comment,
  onChangeComment,
}: PromptCommentModalProps): PromptCommentModal => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const show = useCallback(
    () => setIsConfirmVisible(true),
    [setIsConfirmVisible],
  );
  const hide = useCallback(
    () => setIsConfirmVisible(false),
    [setIsConfirmVisible],
  );

  return {
    modal: (
      <Dialog open={isConfirmVisible} onOpenChange={(open) => !open && hide()}>
        <Stack spacing={3} sx={{mt: 1}}>
          <Label>
            <Typography variant={'h6'}>Prompt Comment:</Typography>
          </Label>
          <Input
            height={10}
            value={comment}
            onChange={(e) => onChangeComment(e.target.value)}
          />
          <DialogFooter>
            <LegacyButton
              variant="ghost"
              onClick={() => {
                hide();
              }}
            >
              Cancel
            </LegacyButton>
            <LegacyButton
              onClick={async () => {
                setIsConfirming(true);
                await onConfirm();
                setIsConfirming(false);
                hide();
              }}
              isLoading={isConfirming}
            >
              Save
            </LegacyButton>
          </DialogFooter>
        </Stack>
      </Dialog>
    ),
    show,
    hide,
  };
};
