/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  PromptRevision,
  useGetPublishedPromptRevision,
  useListPromptRevisions,
} from '@agent-assist/api-typescript-react-query-hooks';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Card, Paper, Stack} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  type LegacyDateRange as DateRange,
  MuiDateRangePicker,
} from '../../../components/DateAndTimeRange/DateAndTimeRange';
import {EmptyResult} from '../../../components/EmptyResult';

import {LoadingSpinner} from '../../../components/LoadingSpinner';
import StatusIndicator, {
  StatusTypeEnum,
} from '../../../components/StatusIndicator';
import {LegacyButton} from '../../../components/ui/legacy-button';
import {PageContainer} from '../../../components/ui/page';
import {usePaginatedTableData} from '../../../hooks/table/table-hooks';
import {
  resetPromptRevision,
  setPromptEditMode,
  usePromptDispatch,
} from '../../../providers/PromptProvider';
import {ErrorPage} from '../../error/error-page';

const PAGE_SIZE = 15;

interface PromptProps {}

export const QAPrompts: FC<PromptProps> = () => {
  const navigate = useNavigate();
  const dispatch = usePromptDispatch();

  const publishedRevision = useGetPublishedPromptRevision();
  const [dateRange, setDateRange] = useState<DateRange | undefined>();

  const revisions = useListPromptRevisions(
    {
      before: dateRange?.to
        ? dateRange.to.getTime() - dateRange.to.getTimezoneOffset() * 60 * 1000
        : undefined,
      after: dateRange?.from
        ? dateRange.from.getTime() -
          dateRange.from.getTimezoneOffset() * 60 * 1000
        : undefined,
      pageSize: PAGE_SIZE,
    },
    {
      retry: false,
    },
  );

  const {items, loading, pagination, refetch, tableIndex} =
    usePaginatedTableData(revisions, 'revisions', {
      pageSize: PAGE_SIZE,
    });
  const isRefetching = items.length > 0 && (loading || revisions.isRefetching);

  const action = (
    <>
      <LegacyButton
        size={'sm'}
        onClick={() => {
          resetPromptRevision(dispatch);
          setPromptEditMode(dispatch, false);
          navigate('/prompts/create');
        }}
      >
        Create Prompt
      </LegacyButton>
      <LegacyButton
        size={'sm'}
        onClick={() => navigate('/prompts/_DEFAULT_PROMPT')}
      >
        View Default Prompt
      </LegacyButton>
      <LegacyButton size={'sm'} onClick={() => navigate('/prompts/live')}>
        View Published Prompt
      </LegacyButton>
    </>
  );

  return (
    <PageContainer title="Prompt Revisions" actions={action}>
      <Stack spacing={4}>
        <Card sx={{p: 2}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MuiDateRangePicker
                onAccept={([from, to]) =>
                  setDateRange({
                    from: from ?? undefined,
                    to: to ?? undefined,
                  })
                }
              />
              <Box sx={{ml: 2}}>
                {isRefetching ? <LoadingSpinner size={'sm'} /> : null}
              </Box>
            </Box>
            <Box>
              <Stack spacing={1} direction="row">
                <Box>{pagination}</Box>
                <IconButton
                  aria-label="refresh"
                  onClick={refetch}
                  disabled={isRefetching}
                >
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Card>

        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Prompt Revision ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Created By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((promptRevision: PromptRevision, index) => {
                const isPublished =
                  publishedRevision.data?.promptRevisionId ===
                  promptRevision.promptRevisionId;
                return (
                  <TableRow
                    key={promptRevision.promptRevisionId}
                    onClick={() =>
                      navigate(`/prompts/${promptRevision.promptRevisionId}`)
                    }
                    hover
                    sx={{cursor: 'pointer'}}
                  >
                    <TableCell>{index + tableIndex}</TableCell>
                    <TableCell>{promptRevision.promptRevisionId}</TableCell>
                    <TableCell>{promptRevision.name}</TableCell>
                    <TableCell>
                      <StatusIndicator
                        status={
                          isPublished
                            ? StatusTypeEnum.SUCCESS
                            : StatusTypeEnum.PENDING
                        }
                      >
                        {isPublished ? 'Live' : 'Unpublished'}
                      </StatusIndicator>
                    </TableCell>
                    <TableCell>
                      {new Date(promptRevision.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell>{promptRevision.createdBy.username}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {revisions.isError ? (
          <ErrorPage errors={[revisions.error]} />
        ) : items.length === 0 ? (
          <div
            className={'flex flex-1 w-full items-center justify-center py-10'}
          >
            {revisions.status === 'loading' ? (
              <LoadingSpinner />
            ) : (
              <EmptyResult text="No prompt revisions to display" />
            )}
          </div>
        ) : null}
      </Stack>
    </PageContainer>
  );
};

export default QAPrompts;
