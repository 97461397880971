/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {AcceptedPromptTypes} from '@agent-assist/api-typescript-react-query-hooks';
import {DEFAULT_PROMPT_QUESTIONS_ANALYTICS} from '@agent-assist/common/lib/inference/default-prompts';
import {Visualisation} from './types';
import {columns} from '../../pages/contact-import/columns';

// TODO combine all the following into one:
export const availableFilters = columns.filter(({key}) =>
  [
    'categorypd',
    'sub-Categorypd',
    'sub-Sub-Categorypd',
    'queueIds',
    'initialDirection',
  ].includes(key),
);

const shouldVisualise = [
  'customer sentiment',
  'agent sentiment',
  'resolution',
  'repeat call',
];

const TITLE_MAP: Record<string, string> = {
  'customer sentiment': 'Customer Sentiment',
  'agent sentiment': 'Agent Cognitive Load',
  resolution: 'Resolution',
  'repeat call': 'Repeat Call',
};

const defaultVisualisations = DEFAULT_PROMPT_QUESTIONS_ANALYTICS.filter(
  (prompt) => shouldVisualise.includes(prompt.key),
);

export const DEFAULT_VISUALISATIONS: Visualisation[] = defaultVisualisations
  .map((prompt) =>
    prompt.properties.promptVisualisations!.map((vis) => ({
      title: TITLE_MAP[prompt.key] || prompt.key,
      questionId: prompt.id,
      tooltip: vis.tooltip,
      visualisationType: vis.visualisationType,
      questionType: prompt.promptType as AcceptedPromptTypes,
    })),
  )
  .flat()
  .sort((a, b) => a.title.localeCompare(b.title));
