/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  type GetBatchContactImportResponseContent,
  type ListBatchContactImportResultsResponseContent,
  type ResponseError,
  useGetBatchContactImport,
  useGetPromptRevision,
  useListBatchContactImportResults,
} from '@agent-assist/api-typescript-react-query-hooks';

import {Button} from '@mui/material';
import type {
  QueryObserverSuccessResult,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import {type FC, useEffect, useState} from 'react';
import {Link as RouterLink, useParams, useSearchParams} from 'react-router-dom';

import {ViewHeader} from './ViewHeader';
import {ViewListComplete} from './ViewListComplete';
import {ViewListIncomplete} from './ViewListIncomplete';
import {ScreenLoader} from '../../components/ScreenLoader';
import {PageContainer} from '../../components/ui/page';
import {ErrorPage} from '../error/error-page';

export const IMPORTED_CONTACTS_PAGE_SIZE = 10;

export interface ProgressCounts {
  readonly totalCount: number;
  readonly processedCount: number;
}

export const ViewContactImport: FC = () => {
  const {contactImportId} = useParams<{contactImportId: string}>();
  if (!contactImportId) throw new Error('No contactImportId');

  // load import job details:
  const contactImport = useGetBatchContactImport({contactImportId});
  // const promptRevisionId = contactImport.data?.promptRevisionId;
  const promptRevision = useGetPromptRevision(
    {promptRevisionId: contactImport.data?.promptRevisionId!},
    {
      enabled: false, // !!contactImport.data?.promptRevisionId,
    },
  );

  const importResults = useListBatchContactImportResults({
    contactImportId,
    pageSize: IMPORTED_CONTACTS_PAGE_SIZE,
  });

  const [liveProgress, setLiveProgress] = useState<ProgressCounts>({
    totalCount: 0,
    processedCount: 0,
  });

  useEffect(() => {
    const {totalCount, totalNeedProcessing} = contactImport.data ?? {};
    if (
      !(
        typeof totalCount === 'number' &&
        typeof totalNeedProcessing === 'number'
      )
    )
      return; // no data to set...
    // processed count only includes successfully processed contacts.
    setLiveProgress({
      totalCount,
      processedCount: totalCount - totalNeedProcessing,
    });
  }, [
    contactImport.isLoading,
    contactImport.isRefetching,
    liveProgress.processedCount,
    liveProgress.totalCount,
  ]);

  const [search] = useSearchParams();
  if (contactImport.isError) {
    return <ErrorPage errors={[contactImport.error]} />;
  }

  if (contactImport.isLoading) {
    return <ScreenLoader />;
  }

  // const importJobInProgress = ["IN_PROGRESS", "STARTED", "INITIALISING"].includes(contactImport.data.status);
  const importJobSuccess = contactImport.data.status === 'SUCCESS';

  // console.log(contactImport.data);

  const isRefetching = false; //items.length > 0 && (loading || importResults.isRefetching || retryAction.isLoading);

  // const disabled = isRefetching || importJobInProgress;

  const actions = (
    <>
      <Button
        component={RouterLink}
        to={`/analytics/${contactImportId}${search ? `?${search}` : ''}`}
      >
        View Analytics
      </Button>
    </>
  );

  return (
    <PageContainer
      title={contactImport.data.name}
      subtitle={contactImportId}
      actions={actions}
    >
      <ViewHeader
        contactImport={contactImport}
        contactImportId={contactImportId}
        liveProgress={liveProgress}
        showLive={!!contactImport.data.totalNeedProcessing}
        promptRevisionData={promptRevision.data}
      />
      <ViewBody
        contactImport={contactImport}
        contactImportId={contactImportId}
        importResults={importResults}
        isLoading={isRefetching}
        importJobSuccess={importJobSuccess}
        search={search.toString()}
      />
    </PageContainer>
  );
};

export default ViewContactImport;

export interface ViewBodyProps {
  contactImport: QueryObserverSuccessResult<
    GetBatchContactImportResponseContent,
    ResponseError
  >;
  contactImportId: string;
  importJobSuccess: boolean;
  importResults: UseInfiniteQueryResult<
    ListBatchContactImportResultsResponseContent,
    ResponseError
  >;
  isLoading: boolean;
  search: string;
}

const ViewBody = (props: ViewBodyProps) => {
  // return <ViewListIncomplete {...props} />;

  if (props.importJobSuccess) {
    return <ViewListComplete {...props} />;
  } else {
    return <ViewListIncomplete {...props} />;
  }
};

/*


const retryAction = useReTryContactAction();

const applyActionType = async (actionType: ActionType) => {
  await retryAction.mutateAsync({
    contactIds: selectedContacts.map((contact) => contact.contactId),
    contactImportId: contactImportId!,
    actionType,
  });
  void contactImport.refetch();
  void importResults.refetch();
};

const handleCheckboxChange = (contact: { contactId: string; status: ContactStatus }) => {
  if (selectedContacts.some((selected) => selected.contactId === contact.contactId)) {
    setSelectedContacts((prevSelectedContacts) =>
      prevSelectedContacts.filter((selected) => selected.contactId !== contact.contactId),
    );
  } else {
    setSelectedContacts((prevSelectedContacts) => [
      ...prevSelectedContacts,
      { contactId: contact.contactId, status: contact.status },
    ]);
  }
};
const hasFailedContacts = selectedContacts.some((contact) => contact.status === "FAILED");
if selected contacts does not have audio disable the Transcribe options
const hasAudio = items.every(item => selectedContacts.some((contact) => contact.contactId === item.contactId && item.audioS3Key !== undefined))
const options = hasFailedContacts ? [...contactSuccessActions, ...contactFailedActions] : contactSuccessActions;
const disableRetryContactsAction = disabled || retryAction.isLoading;
*/
