import {type ClassValue, clsx} from 'clsx';
import {twMerge} from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getInitials(firstName: string, lastName: string): string {
  const familyInitial = firstName.charAt(0);
  const givenInitial = lastName.charAt(0);
  return `${familyInitial}${givenInitial}`;
}

export function prettyTitleString(str: string): string {
  return str
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ');
}
