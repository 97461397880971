/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {FC} from 'react';
import {Navigate, Route, Routes as ReactRoutes} from 'react-router-dom';
import {Redirect as RedirectWithParams} from 'react-router6-redirect';

import {useDocumentTitle} from './hooks/useDocumentTitle';
import {AnalyticsPage} from './pages/analytics/AnalyticsPage';
import {CalibrationPage} from './pages/CalibrationPage';
import {ContactImports} from './pages/contact-import/contact-imports';
import {ViewContactImport} from './pages/contact-import/ViewContactImport';
import {ContactViewPage} from './pages/ContactViewPage';
import {DataDialog} from './pages/data-dialog/data-dialog';
import {Dev} from './pages/Dev/Dev';
import {DevMUITheme} from './pages/Dev/DevMUITheme';
import {NotFound} from './pages/NotFound';
import {PromptViewPage} from './pages/PromptViewPage';
import {CreateQAPrompts} from './pages/QA/prompts/createQAPrompts';
import {QAPrompts} from './pages/QA/prompts/ListQAPrompts';
import {QAPublishedPrompt} from './pages/QA/prompts/PublishedQAPrompt';
import {Settings} from './pages/settings/Settings';
import ListTrusstedAgents from './pages/trussted-agent/ListAgents';
import ViewAgent from './pages/trussted-agent/ViewAgent';

const Routes: FC = () => {
  useDocumentTitle();

  return (
    <ReactRoutes>
      {/* CONTACTS */}
      <Route
        path="/contacts/:contactImportId/contact/:contactId"
        element={<ContactViewPage />}
      />
      <Route
        path="/contacts/:contactImportId"
        element={<ViewContactImport />}
      />
      <Route path="/contacts" element={<ContactImports />} />

      {/* AGENTS */}
      <Route path="/agents" element={<ListTrusstedAgents />} />
      <Route path="/agents/edit/:agentId" element={<ViewAgent />} />
      <Route path="/agents/edit" element={<ListTrusstedAgents />} />
      <Route path="/agents/create" element={<ViewAgent />} />

      {/* ANALYTICS */}
      <Route
        path="/analytics/:contactImportId/:promptRevisionId"
        element={<AnalyticsPage />}
      />
      <Route
        path="/analytics/:contactImportId" // promptRevisionId is optional and will adopt a default if missing
        element={<AnalyticsPage />}
      />
      <Route path="/analytics" element={<AnalyticsPage />} />

      {/* PROMPTS */}
      <Route path="/prompts/create" element={<CreateQAPrompts />} />
      <Route
        path="/prompts/edit/:promptRevisionId"
        element={<CreateQAPrompts />}
      />
      <Route
        path="/prompts/edit"
        element={<RedirectWithParams to="/prompts" />}
      />
      <Route path="/prompts" element={<QAPrompts />} />
      <Route path="/prompts/:promptRevisionId" element={<PromptViewPage />} />
      <Route path="/prompts/live" element={<QAPublishedPrompt />} />
      <Route path="/calibration" element={<CalibrationPage />} />

      {/* OTHER */}
      <Route path="/data-dialog" element={<DataDialog />} />
      <Route path="/data-dialog/:contactImportId" element={<DataDialog />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/dev" element={<Dev />} />
      <Route path="/dev/mui" element={<DevMUITheme />} />

      {/* CATCH */}
      <Route path="/" element={<Navigate to="/analytics" />} />
      <Route path="*" element={<NotFound />} />
    </ReactRoutes>
  );
};

export default Routes;
