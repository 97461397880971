/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Box, Stack} from '@mui/material';
import Chip from '@mui/material/Chip';
import {useColorScheme} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {ArrowSquareOut as ArrowSquareOutIcon} from '@phosphor-icons/react/dist/ssr/ArrowSquareOut';
import {CaretDown as CaretDownIcon} from '@phosphor-icons/react/dist/ssr/CaretDown';
import {CaretRight as CaretRightIcon} from '@phosphor-icons/react/dist/ssr/CaretRight';
import {useFlags} from 'launchdarkly-react-client-sdk';

import {useState} from 'react';
import {navColorStyles} from './styles';
import {RouterLink} from '../../components/link';
import {Logo} from '../../components/logo';
import {FeatureFlags} from '../../config';
import {usePathname} from '../../hooks/use-pathname';
import {isNavItemActive} from '../../lib/is-nav-item-active';

import type {NavItemConfig} from '../../types/nav';
import type {NavColor} from '../../types/settings';

interface SideNavProps {
  color?: NavColor;
  mainItems?: NavItemConfig[];
  secondaryItems?: NavItemConfig[];
  compact: boolean;
}

export function SideNavDesktop({
  color = 'discrete',
  mainItems = [],
  secondaryItems = [],
  compact,
}: SideNavProps): React.JSX.Element {
  const pathname = usePathname();
  const {colorScheme = 'dark'} = useColorScheme();
  const styles = navColorStyles[colorScheme][color];

  return (
    <Box
      sx={{
        ...styles,
        bgcolor: 'var(--SideNav-background)',
        borderRight: 'var(--SideNav-border)',
        color: 'var(--SideNav-color)',
        display: {xs: 'none', lg: 'flex'},
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        left: 0,
        position: 'fixed',
        top: 0,
        width: 'var(--SideNav-width)',
        zIndex: 'var(--SideNav-zIndex)',
      }}
    >
      <Box>
        <Box sx={{pt: 3, pl: 3.5, pb: 2}}>
          <Box component={RouterLink} href={'/'} sx={{display: 'inline-flex'}}>
            <Logo color={colorScheme} height={26} compact={compact} />
          </Box>
        </Box>
        <Box
          component="nav"
          sx={{
            overflowY: 'auto',
            p: 2,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {display: 'none'},
          }}
        >
          {renderNavGroups({items: mainItems, pathname, compact})}
        </Box>
      </Box>
      <Box
        component="nav"
        sx={{
          overflowY: 'auto',
          p: 2,
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {display: 'none'},
        }}
      >
        {renderNavGroups({items: secondaryItems, pathname, compact})}
      </Box>
    </Box>
  );
}

const renderNavGroups: React.FC<{
  items: NavItemConfig[];
  pathname: string;
  compact: boolean;
}> = ({items, pathname, compact}) => {
  const children = items.reduce(
    (acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
      acc.push(
        <Stack component="li" key={curr.key} spacing={1.5}>
          {curr.title ? (
            <div>
              <Typography
                sx={{
                  color: 'var(--NavGroup-title-color)',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                }}
              >
                {curr.title}
              </Typography>
            </div>
          ) : null}
          <div>
            {renderNavItems({
              compact,
              depth: 0,
              items: curr.items,
              pathname,
            })}
          </div>
        </Stack>,
      );

      return acc;
    },
    [],
  );

  return (
    <Stack component="ul" spacing={2} sx={{listStyle: 'none', m: 0, p: 0}}>
      {children}
    </Stack>
  );
};

function renderNavItems({
  depth = 0,
  items = [],
  pathname,
  compact,
}: {
  depth: number;
  items?: NavItemConfig[];
  pathname: string;
  compact: boolean;
}): React.JSX.Element {
  const children = items.reduce(
    (acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
      const allFlags = useFlags<FeatureFlags>();
      if (
        curr.featureFlag &&
        !allFlags[curr.featureFlag as keyof FeatureFlags]
      ) {
        return acc;
      }

      const {items: childItems, key, ...item} = curr;

      const forceOpen = childItems
        ? Boolean(
            childItems.find(
              (childItem) =>
                childItem.href && pathname.startsWith(childItem.href),
            ),
          )
        : false;

      acc.push(
        <NavItem
          depth={depth}
          forceOpen={forceOpen}
          key={key}
          pathname={pathname}
          {...item}
          compact={compact}
        >
          {childItems
            ? renderNavItems({
                depth: depth + 1,
                pathname,
                items: childItems,
                compact,
              })
            : null}
        </NavItem>,
      );

      return acc;
    },
    [],
  );

  return (
    <Stack
      component="ul"
      data-depth={depth}
      spacing={1}
      sx={{listStyle: 'none', m: 0, p: 0}}
    >
      {children}
    </Stack>
  );
}

interface NavItemProps extends Omit<NavItemConfig, 'items'> {
  children?: React.ReactNode;
  depth: number;
  forceOpen?: boolean;
  pathname: string;
  compact: boolean;
}

function NavItem({
  children,
  depth,
  disabled,
  external,
  forceOpen = false,
  href,
  icon,
  label,
  matcher,
  pathname,
  title,
  compact,
}: NavItemProps): React.JSX.Element {
  const [open, setOpen] = useState<boolean>(forceOpen);
  const active = isNavItemActive({disabled, external, href, matcher, pathname});
  const Icon = icon || null;
  const ExpandIcon = open ? CaretDownIcon : CaretRightIcon;
  const isBranch = children && !href;

  return (
    <Box component="li" data-depth={depth} sx={{userSelect: 'none'}}>
      <Tooltip
        title={title}
        placement={'right'}
        arrow
        disableHoverListener={!compact}
      >
        <Box
          {...(isBranch
            ? {
                onClick: (): void => {
                  setOpen(!open);
                },
                onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>): void => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    setOpen(!open);
                  }
                },
                role: 'button',
              }
            : {
                ...(href
                  ? {
                      component: external ? 'a' : RouterLink,
                      href,
                      target: external ? '_blank' : undefined,
                      rel: external ? 'noreferrer' : undefined,
                    }
                  : {role: 'button'}),
              })}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            color: 'var(--NavItem-color)',
            cursor: 'pointer',
            display: 'flex',
            flex: '0 0 auto',
            gap: 1,
            p: '6px 16px',
            position: 'relative',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            ...(disabled && {
              bgcolor: 'var(--NavItem-disabled-background)',
              color: 'var(--NavItem-disabled-color)',
              cursor: 'not-allowed',
            }),
            ...(active && {
              bgcolor: 'var(--NavItem-active-background)',
              color: 'var(--NavItem-active-color)',
              ...(depth > 0 && {
                '&::before': {
                  bgcolor: 'var(--NavItem-children-indicator)',
                  borderRadius: '2px',
                  content: '" "',
                  height: '20px',
                  left: '-14px',
                  position: 'absolute',
                  width: '3px',
                },
              }),
            }),
            ...(open && {color: 'var(--NavItem-open-color)'}),
            '&:hover': {
              ...(!disabled &&
                !active && {
                  bgcolor: 'var(--NavItem-hover-background)',
                  color: 'var(--NavItem-hover-color)',
                }),
            },
          }}
          tabIndex={0}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              flex: '0 0 auto',
            }}
          >
            {Icon ? (
              <Icon
                fill={
                  active
                    ? 'var(--NavItem-icon-active-color)'
                    : 'var(--NavItem-icon-color)'
                }
                sx={{fontSize: 'var(--icon-fontSize-md)'}}
              />
            ) : null}
          </Box>
          {!compact && (
            <Box sx={{flex: '1 1 auto'}}>
              <Typography
                component="span"
                sx={{
                  color: 'inherit',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: '28px',
                }}
              >
                {title}
              </Typography>
            </Box>
          )}
          {label ? <Chip color="primary" label={label} size="small" /> : null}
          {external && !compact ? (
            <Box sx={{alignItems: 'center', display: 'flex', flex: '0 0 auto'}}>
              <ArrowSquareOutIcon
                color="var(--NavItem-icon-color)"
                fontSize="var(--icon-fontSize-sm)"
              />
            </Box>
          ) : null}
          {isBranch ? (
            <Box sx={{alignItems: 'center', display: 'flex', flex: '0 0 auto'}}>
              <ExpandIcon
                color="var(--NavItem-expand-color)"
                fontSize="var(--icon-fontSize-sm)"
              />
            </Box>
          ) : null}
        </Box>
      </Tooltip>
    </Box>
  );
}
