/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ChatRequest,
  Message,
  useChat,
  useListDataDialogPrompts,
  useGetBatchContactImport,
} from '@agent-assist/api-typescript-react-query-hooks';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Card, CardContent, Button, Box} from '@mui/material';
import {Lightbulb, Trash2} from 'lucide-react';
import {useEffect, useRef, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {DialogTranscript} from './DialogTranscript';
import {ListPrompts} from './ListPrompts';
import {PromptInput} from './PromptInput';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import {
  ContactImportIds,
  SelectContactImport,
} from '../../components/SelectContactImport/SelectContactImport';
import StatusIndicator, {
  StatusTypeEnum,
} from '../../components/StatusIndicator';
import {LegacyButton} from '../../components/ui/legacy-button';
import {PageContainer} from '../../components/ui/page';
import {useActionConfirmation} from '../../hooks/modal/action-confirmation';
import {DEFAULT_PROMPT_ID} from '../../lib/constants';
import {useContactImportsStore} from '../../stores/contactImportsStore';
interface SearchResult {
  contact_import_id: string;
  contact_id: string;
  summary: string;
}

// same as type `Message` but with `createdAt` and role is limited:
export interface TimeStampedMessage {
  content: string;
  createdAt: number;
  role: 'user' | 'assistant';
  searchResults?: Array<SearchResult>;
}

export const defaultQuestions = [
  'What were the top 5 intents/reasons for customers contacting?',
  'What percentage of calls were not resolved during the first contact?',
  'How satisfied were customers with the outcome of interactions?',
];

export const DataDialog = () => {
  const navigate = useNavigate();
  const params = useParams();
  const paramContactImportId = params.contactImportId;
  const [contactImportId, setContactImportId] = useState<string>();
  const contactImports = useContactImportsStore(
    (state) => state.contactImports,
  );
  const result = useChat();
  const [messages, setMessages] = useState<TimeStampedMessage[]>([]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (paramContactImportId) {
      setContactImportId(paramContactImportId);
    }
  }, [paramContactImportId]);

  useEffect(() => {
    if (!paramContactImportId && !contactImportId && contactImports.length) {
      setContactImportId(contactImports[0].contactImportId);
    }
  }, [paramContactImportId, contactImports, contactImportId]);

  const list = useListDataDialogPrompts();
  const clearConfirmation = useActionConfirmation({
    prompt: 'Are you sure you want to clear the chat history?',
    confirmationButtonText: 'Clear',
    onConfirm: () =>
      new Promise((resolve) => {
        setMessages([]);
        resolve();
      }),
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [messages]);

  const sendMessage = async (messageContent: string) => {
    setLoading(true);
    const userMessage: TimeStampedMessage = {
      content: messageContent,
      createdAt: new Date().getTime(),
      role: 'user',
    };

    const allMessages = [...messages, userMessage];
    setMessages(allMessages);

    // strip out `createdAt`:
    const chatRequestMessages: Message[] = allMessages.map(
      ({content, role}) => ({content, role}),
    );
    const chatRequest: ChatRequest = {
      chatRequestContent: {
        messages: chatRequestMessages,
        contactImportId: contactImportId,
      },
    };

    try {
      const mutateResult = await result.mutateAsync(chatRequest);
      const responseMessage: TimeStampedMessage = {
        content:
          mutateResult.messages[mutateResult.messages.length - 1].content,
        createdAt: new Date().getTime(),
        role: 'assistant',
        searchResults: mutateResult.searchResults
          ? JSON.parse(mutateResult.searchResults)
          : undefined,
      };
      setMessages([...allMessages, responseMessage]);
    } catch (err) {
      const errorMessage: TimeStampedMessage = {
        content: 'Sorry something went wrong processing your request',
        createdAt: new Date().getTime(),
        role: 'assistant',
      };
      setMessages([...allMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const refetchSuggestions = async () => {
    await list.refetch();
  };

  const dialogActive = messages.length > 0;

  if (!contactImportId) {
    return (
      <Box
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  // const commonProps = {
  //   contactImportId
  // };

  const pageActions = (
    <>
      <Button
        color="secondary"
        startIcon={<CloudUploadIcon />}
        variant="outlined"
        component={RouterLink}
        to={`/contacts/${contactImportId}`}
      >
        Go to Import
      </Button>
      <div className={'flex flex-row gap-5'}>
        <CustomStatusIndicator contactImportId={contactImportId} />
        <SelectContactImport
          handleContactImportIdChanged={(contactImport: ContactImportIds) =>
            navigate(
              `/data-dialog/${contactImport.contactImportId}${
                // omit promptRevisionId from URL if it is the default:
                contactImport.promptRevisionId === DEFAULT_PROMPT_ID
                  ? ''
                  : `/${contactImport.promptRevisionId}`
              }`,
            )
          }
          initialValue={contactImportId}
        />
      </div>
    </>
  );

  return (
    <PageContainer title="Data Dialog" actions={pageActions}>
      {clearConfirmation.modal}
      <Card>
        <CardContent>
          <div
            className="grid w-full gap-4"
            style={{
              gridTemplateColumns: '1fr 200px',
              gridTemplateRows: '1fr 40px',
              height: 'calc(100vh - 350px)', // -(header height + padding)
            }}
          >
            <div className="overflow-y-auto relative" ref={containerRef}>
              {dialogActive && (
                <div
                  className="sticky right-2 top-2"
                  style={{
                    // needed to position to right:
                    width: 'max-content',
                    marginLeft: 'auto',
                  }}
                >
                  <LegacyButton
                    variant="outline"
                    size="icon"
                    onClick={clearConfirmation.show}
                  >
                    <Trash2 />
                  </LegacyButton>
                </div>
              )}

              <DialogTranscript
                messages={messages}
                refetch={refetchSuggestions}
              />

              {isLoading && (
                <div className="flex w-full justify-end p-6">
                  <LoadingSpinner size={'lg'} />
                </div>
              )}
              {!dialogActive && (
                <ContainerQuery>
                  <Container>
                    {defaultQuestions.map((questionText, qi) => {
                      return (
                        <BtnText
                          className="border rounded-lg hover:bg-accent"
                          onClick={() => sendMessage(questionText)}
                          key={`default-msg-${qi}`}
                        >
                          <div>
                            <Lightbulb size="32" />
                          </div>
                          <div className="text-left">{questionText}</div>
                        </BtnText>
                      );
                    })}
                  </Container>
                </ContainerQuery>
              )}
            </div>

            <div className="relative row-span-2">
              <ListPrompts
                prompts={list.data?.prompts}
                refetch={refetchSuggestions}
                isFetching={list.isFetching}
                onSelectPrompt={(presetText: string) => sendMessage(presetText)}
              />
            </div>

            <PromptInput
              sendMessage={sendMessage}
              dialogActive={dialogActive}
            />
          </div>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

const CustomStatusIndicator = ({
  contactImportId,
}: {
  contactImportId: string;
}) => {
  const contactImportDetails = useGetBatchContactImport({contactImportId});
  const [contactImportStatus, setContactImportStatus] =
    useState<StatusTypeEnum>();
  useEffect(() => {
    setContactImportStatus(contactImportDetails.data?.status as StatusTypeEnum);
  }, [contactImportDetails.data?.status]);

  if (contactImportStatus === StatusTypeEnum.PARTIAL_SUCCESS) {
    return <StatusIndicator status={contactImportStatus} />;
  } else {
    return null;
  }
};

export default DataDialog;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

const ContainerQuery = styled.div`
  container-type: inline-size;

  @container (width < 800px) {
    ${Container} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @container (width < 500px) {
    ${Container} {
      grid-template-columns: 1fr;
    }
  }
`;

const BtnText = styled.button`
  display: grid;
  padding: 16px;
  grid-template-columns: 50px 1fr;
  align-items: center;
  height: 100%;
`;
