/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {format} from 'date-fns';
import React, {FC} from 'react';
import styled from 'styled-components';
import {formatMessage, type MessageChunk} from './formatMessage';
import {wrappedJson} from './wrappedJson';
import {cn} from '../../lib/utils';

interface ChatBubbleProps {
  timeStamp: number;
  isBeginning: boolean;
  isEnd: boolean;
  isLeft: boolean;
  content: string;
  userTitle: string;
  actionIcons?: React.ReactNode;
}

const BulletPoint = styled.li`
  list-style: none;
  margin: 8px 0;
`;

export const ChatBubble: FC<ChatBubbleProps> = ({
  timeStamp,
  isBeginning,
  isEnd,
  isLeft,
  userTitle,
  content,
  actionIcons,
}) => {
  const chunks = formatMessage(content);
  return (
    <div
      className={cn('flex w-full', isLeft ? 'justify-start' : 'justify-end')}
    >
      <div className={'flex flex-col gap-y-1 max-w-[80%]'}>
        {isBeginning ? (
          <p className={'font-semibold'} role="participant-role">
            {userTitle}
          </p>
        ) : null}

        <div className={'flex'}>
          <div
            className={cn(
              'p-2 rounded-md',
              isLeft
                ? 'bg-primary text-primary-foreground'
                : 'bg-accent text-accent-foreground',
              !isBeginning && !isEnd
                ? 'my-1'
                : isBeginning && !isEnd
                  ? 'mb-1'
                  : !isBeginning && isEnd
                    ? 'mt-1'
                    : 'm-0',
            )}
            dir="auto"
          >
            {chunks.map(({fmt, value}: MessageChunk, i: number) => {
              switch (fmt) {
                case 'bulletpoint':
                  return <BulletPoint key={i}>{value}</BulletPoint>;
                case 'json':
                  return wrappedJson(value, i);
                default: // case "string":
                  return <p key={i}>{value}</p>;
              }
            })}
          </div>
          {Boolean(actionIcons) && <div className="ml-2">{actionIcons}</div>}
        </div>

        {isEnd ? (
          <div
            className={cn(
              'flex w-full',
              isLeft ? 'justify-start' : 'justify-end',
            )}
          >
            <p className={'text-xs text-muted-foreground'}>
              {format(timeStamp, 'h:mm aaa')}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
