/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

// feel free to import mui dialog subcomponents directly, but also use the main `Dialog` herein component which is responsive with a close button
// this file was just a transformer from radix -> mui dialogs. it adopts the old radix-ui's dialog "API" and maps these props to mui's dialog
// it was made so we don't have to rewrite all dialogs

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';

interface RadixDialogProps {
  children: React.ReactNode;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  title?: string;
}

const Dialog = ({children, open, onOpenChange, title}: RadixDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = () => {
    onOpenChange(false);
  };
  return (
    <MuiDialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{sx: {minWidth: fullScreen ? 'auto' : '400px'}}}
    >
      {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(t) => ({
          position: 'absolute',
          right: 16,
          top: 16,
          color: t.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </MuiDialog>
  );
};

interface DialogContentProps
  extends React.DialogHTMLAttributes<HTMLDivElement> {
  description?: string;
}
const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  ({children}, ref) => (
    <MuiDialogContent ref={ref}>{children}</MuiDialogContent>
  ),
);

// there is no need to use this component:
const DialogHeader = ({children}: React.HTMLAttributes<HTMLDivElement>) => (
  <>{children}</>
);

const DialogFooter = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <DialogActions {...props}>{children}</DialogActions>
);

const DialogTitle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({children}, ref) => <MuiDialogTitle ref={ref}>{children}</MuiDialogTitle>);

export {Dialog, DialogContent, DialogHeader, DialogFooter, DialogTitle};
