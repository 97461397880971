/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {ContactImportStatus} from '@agent-assist/api-typescript-react-query-hooks';
import {CheckCircle2, XCircle} from 'lucide-react';
import {Progress} from '../ui/progress';

interface ContactImportProgressBarProps {
  readonly processedCount: number;
  readonly totalCount: number;
  readonly status: ContactImportStatus;
}
const ContactImportProgressBar = ({
  processedCount,
  totalCount,
  status,
}: ContactImportProgressBarProps) => {
  const progress = (processedCount / totalCount) * 100;
  switch (status) {
    case 'STARTED':
      return (
        <div className={'flex w-full gap-x-2 items-center'}>
          <Progress value={0} className={'w-full'} />
          <span className={'text-slate-500 whitespace-nowrap'}>{`0%`}</span>
        </div>
      );
    case 'INITIALISING':
      return (
        <div className={'flex w-full gap-x-2 items-center'}>
          <Progress value={0} className={'w-full'} />
          <span className={'text-slate-500 whitespace-nowrap'}>{`0%`}</span>
        </div>
      );
    case 'IN_PROGRESS':
      return (
        <div className={'flex w-full gap-x-2 items-center'}>
          <Progress value={progress ?? 0} className={'w-full'} />
          <span
            className={'text-slate-500 whitespace-nowrap'}
          >{`${progress ? Math.round(progress) : 0}%`}</span>
        </div>
      );
    case 'PARTIAL_SUCCESS':
    case 'SUCCESS':
      return (
        <div className={'flex w-fit gap-x-2 items-center text-green-500'}>
          <CheckCircle2 />
          {/* TODO: this is a hack to show the total when it's completed. the fix for managing processed count needs more work */}
          <span
            className={''}
          >{`${processedCount} of ${totalCount} Contacts Imported`}</span>
        </div>
      );
    case 'FAILURE':
    default:
      return (
        <div className={'flex w-fit gap-x-2 items-center text-red-500'}>
          <XCircle />
          <span className={''}>Import Job Failed</span>
        </div>
      );
  }
};

export default ContactImportProgressBar;
