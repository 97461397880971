/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import type {Components} from '@mui/material/styles';

import {MuiAlert} from './alert';
import {MuiAutocomplete} from './autocomplete';
import {MuiAvatar} from './avatar';
import {MuiBackdrop} from './backdrop';
import {MuiButton} from './button';
import {MuiButtonBase} from './button-base';
import {MuiButtonGroup} from './button-group';
import {MuiCard} from './card';
import {MuiCardActions} from './card-actions';
import {MuiCardContent} from './card-content';
import {MuiCardHeader} from './card-header';
import {MuiCheckbox} from './checkbox';
import {MuiChip} from './chip';
import {MuiDialog} from './dialog';
import {MuiFilledInput} from './filled-input';
import {MuiFormControlLabel} from './form-control-label';
import {MuiFormGroup} from './form-group';
import {MuiFormLabel} from './form-label';
import {MuiIconButton} from './icon-button';
import {MuiInput} from './input';
import {MuiInputBase} from './input-base';
import {MuiInputLabel} from './input-label';
import {MuiLinearProgress} from './linear-progress';
import {MuiLink} from './link';
import {MuiList} from './list';
import {MuiListItem} from './list-item';
import {MuiListItemAvatar} from './list-item-avatar';
import {MuiListItemButton} from './list-item-button';
import {MuiListItemIcon} from './list-item-icon';
import {MuiListItemText} from './list-item-text';
import {MuiMenu} from './menu';
import {MuiMenuItem} from './menu-item';
import {MUIDataTableBodyRow} from './mui-data-table-body-row';
import {MuiOutlinedInput} from './outlined-input';
import {MuiPaper} from './paper';
import {MuiPopover} from './popover';
import {MuiRadio} from './radio';
import {MuiSelect} from './select';
import {MuiStack} from './stack';
import {MuiSwitch} from './switch';
import {MuiTab} from './tab';
import {MuiTableBody} from './table-body';
import {MuiTableCell} from './table-cell';
import {MuiTableHead} from './table-head';
import {MuiTablePagination} from './table-pagination';
import {MuiTabs} from './tabs';
import {MuiTimelineConnector} from './timeline-connector';
import {MuiToggleButton} from './toggle-button';
import {MuiToggleButtonGroup} from './toggle-button-group';
import {MuiTooltip} from './tooltip';
import type {Theme} from '../types';

export const components = {
  MuiAlert,
  MuiAutocomplete,
  MuiAvatar,
  MuiBackdrop,
  MuiButton,
  MuiButtonBase,
  MuiButtonGroup,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiCheckbox,
  MuiChip,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiIconButton,
  MuiInput,
  MuiInputBase,
  MuiDialog,

  // TODO: Revisit these components
  MuiFormLabel, // Affects DateTimePicker layout
  MuiInputLabel, // Affects DateTimePicker layout
  MuiOutlinedInput, // Affects DateTimePicker layout

  MuiLinearProgress,
  MuiLink,
  MuiList,
  MuiListItem,
  MuiListItemAvatar,
  MuiListItemButton,
  MuiListItemIcon,
  MuiListItemText,
  MuiMenu,
  MuiMenuItem,
  MuiPaper,
  MuiPopover,
  MuiRadio,
  MuiSelect,
  MuiStack,
  MuiSwitch,
  MuiTab,
  MuiTableBody,
  MuiTableCell,
  MuiTableHead,
  MuiTablePagination,
  MuiTabs,
  MuiTimelineConnector,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTooltip,

  // NON-STANDARD COMPONENTS
  // @ts-ignore
  MUIDataTableBodyRow,
} satisfies Components<Theme>;
