/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactDetailsFilter,
  ContactImportAggrQuestionScores,
  ContactImportAggrSectionScores,
  useGetAnalyticsAggregationScores,
  useGetBatchContactImport,
  useGetPromptRevision,
  useGetPublishedPromptRevision,
  useGetQaAggregationSectionScore,
} from '@agent-assist/api-typescript-react-query-hooks';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {capitalCase} from 'change-case';
import {CornerDownRight} from 'lucide-react';
import {Fragment, useEffect, useState} from 'react';
import {Row} from './QAExpandableDataGrid-datatables';

import {localTimeToDatabaseTime} from '../../lib/formatDate';
import {formatPercentage} from '../../lib/formatNumber';
import {cleanseFilters} from '../../pages/contact-import/cleanseFilters';
import {
  AnalyticsGraphCard,
  AnalyticsGraphCardProps,
} from '../Analytics/AnalyticsGraphCard';
import {BarBackground, BarGraph, BarPercentage} from '../HierBar/HierBar';

export type PromptGroupAggrScoreFormattedRow = {
  name: string;
  sectionTotalScore: number;
  questions: {
    key: string;
    question: string;
    totalScore: number | string;
    isChild: boolean;
    isParent: boolean;
  }[];
};

const gridStyle: React.CSSProperties = {
  gridTemplateColumns: '24px 1fr 160px',
  rowGap: '8px',
  columnGap: '12px',
};

function QuestionsSet(props: {row: PromptGroupAggrScoreFormattedRow}) {
  const {
    row: {name, sectionTotalScore, questions},
  } = props;
  const [questionsOpen, setQuestionsOpen] = useState(false);

  return (
    <>
      <div className="grid w-full border-t" style={gridStyle}>
        <div>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setQuestionsOpen(!questionsOpen)}
          >
            {questionsOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </div>
        <div
          className="flex items-center"
          onClick={() => setQuestionsOpen(!questionsOpen)}
          aria-label="prompt revisions title"
        >
          {name}
        </div>
        <div className="flex items-center mr-4">
          <PromptBarGraph
            ariaLabel="prompt revisions average"
            value={sectionTotalScore}
          />
        </div>
      </div>
      <Collapse in={questionsOpen} timeout="auto" unmountOnExit>
        <div className="grid w-full pt-2 pb-4" style={gridStyle}>
          {questions?.length > 0 ? (
            questions.map(({key, question, totalScore, isChild}, qi) => {
              return (
                <Fragment key={question + 'qi' + qi}>
                  <div /* spacer */ />
                  <div className="items-center">
                    <div className="flex flex-row text-sm capitalize">
                      {isChild && (
                        <CornerDownRight className="mr-1" size={16} />
                      )}
                      <span aria-label="prompt revision question">{key}</span>
                      <Tooltip title={question}>
                        <HelpOutlineIcon sx={{ml: 1, fontSize: 14}} />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex items-center mr-4">
                    {typeof totalScore === 'string' ? (
                      <div
                        className="text-sm"
                        aria-label="prompt revision score"
                      >
                        {totalScore}
                      </div>
                    ) : (
                      <PromptBarGraph
                        ariaLabel="prompt revision score"
                        scale={0.8}
                        value={totalScore}
                      />
                    )}
                  </div>
                </Fragment>
              );
            })
          ) : (
            <div className="text-center text-s col-span-3 p-4">
              No questions found for {name} evaluation categories.
            </div>
          )}
        </div>
      </Collapse>
    </>
  );
}

const PromptBarGraph = ({
  ariaLabel,
  scale = 1,
  value,
}: {
  ariaLabel: string;
  scale?: number;
  value: number;
}): JSX.Element => {
  return (
    <BarBackground
      style={
        scale === 1
          ? {}
          : {transformOrigin: 'left', transform: `scale(${scale})`}
      }
    >
      <BarGraph background="temperature" width={value / 100} isFirst isLast />
      <BarPercentage aria-label={ariaLabel}>
        {formatPercentage(value)}
      </BarPercentage>
    </BarBackground>
  );
};

interface PromptSectionProps extends AnalyticsGraphCardProps {
  data: PromptGroupAggrScoreFormattedRow[];
}

export const PromptSection = ({
  data,
  isLoading,
  noDataFound,
  title,
}: PromptSectionProps) => {
  return (
    <>
      <AnalyticsGraphCard
        title={title}
        isLoading={isLoading}
        minHeight="min-h-[120px]"
        noDataFound={noDataFound}
      >
        <div className="text-left w-full">
          <div className="grid py-2" style={gridStyle}>
            <div /* spacer */ />
            <div className="font-semibold">Evaluation Categories</div>
            <div className="font-semibold">
              Overall Score
              <Tooltip title="Excludes N/A results">
                <InfoIcon sx={{ml: 1}} />
              </Tooltip>
            </div>
          </div>
          {data.map((row, i) => (
            <QuestionsSet key={`${row.name}-i-${i}`} row={row} />
          ))}
        </div>
      </AnalyticsGraphCard>
    </>
  );
};

export const PromptRevisionScores = ({
  contactImportId,
  filters,
}: {
  contactImportId: string;
  filters: ContactDetailsFilter[];
}) => {
  const [formattedRows, setFormattedRows] = useState<
    PromptGroupAggrScoreFormattedRow[]
  >([]);
  const getQaAggregationSectionScore = useGetQaAggregationSectionScore();
  const [qaAnalyticsAggrSectionScores, setQaAnalyticsAggrSectionScores] =
    useState<ContactImportAggrSectionScores[]>([]);
  const [qaAnalyticsAggrQuestionScores, setQaAnalyticsAggrQuestionScores] =
    useState<ContactImportAggrQuestionScores[]>([]);
  const getQaAggregationQuestionScore = useGetAnalyticsAggregationScores();

  useEffect(() => {
    const {filters: cleansedFilters, from, to} = cleanseFilters(filters);
    const getQAAnalytics = async () => {
      const result = await getQaAggregationSectionScore.mutateAsync({
        contactImportId,
        getQaAggregationSectionScoreRequestContent: {
          from: localTimeToDatabaseTime(from),
          to: localTimeToDatabaseTime(to),
          filters: cleansedFilters,
        },
      });

      setQaAnalyticsAggrSectionScores(result.analytics);
    };
    const getQAAggregationScores = async () => {
      const scoreResults = await getQaAggregationQuestionScore.mutateAsync({
        contactImportId,
        getAnalyticsAggregationScoresRequestContent: {
          from: localTimeToDatabaseTime(from),
          to: localTimeToDatabaseTime(to),
          filters: cleansedFilters,
        },
      });
      setQaAnalyticsAggrQuestionScores(scoreResults.analytics);
    };
    void getQAAnalytics();
    void getQAAggregationScores();
  }, [contactImportId, filters]);

  const {data: contactImportData} = useGetBatchContactImport(
    {contactImportId},
    {
      enabled: !!contactImportId,
    },
  );
  const {data: promptRevisionData, isSuccess: promptRevisionSuccess} =
    contactImportData?.promptRevisionId
      ? useGetPromptRevision({
          promptRevisionId: contactImportData.promptRevisionId,
        })
      : useGetPublishedPromptRevision();

  useEffect(() => {
    if (!promptRevisionData) return;
    const newFormattedRows: PromptGroupAggrScoreFormattedRow[] = Object.entries(
      promptRevisionData.promptGroups,
    )
      .filter(([_, group]) => group.promptGroupType === 'qa')
      .map(([promptGroupName, group]) => {
        const sectionScore = qaAnalyticsAggrSectionScores.find(
          (section) => section.promptGroupName === promptGroupName,
        )?.scorePercentage;

        const totalSectionScore = Number(sectionScore) || 0;

        const questions = group.questions.map(
          ({id, key, question, properties}) => {
            const questionScoreObj = qaAnalyticsAggrQuestionScores.find(
              (score) => score.questionId === id,
            );
            const questionScore =
              questionScoreObj?.scorePercentage === 'N/A'
                ? 'N/A'
                : Number(questionScoreObj?.scorePercentage || 0);

            return {
              key,
              question,
              totalScore: questionScore,
              isChild: properties.isChild,
              isParent: properties.isParent,
            };
          },
        );

        return {
          name: capitalCase(promptGroupName),
          sectionTotalScore: totalSectionScore,
          questions,
        };
      });

    setFormattedRows(newFormattedRows);
  }, [
    qaAnalyticsAggrQuestionScores,
    qaAnalyticsAggrSectionScores,
    promptRevisionData,
  ]);
  const aggAnalyticsSuccess = qaAnalyticsAggrQuestionScores !== undefined;
  const allSuccess = [
    qaAnalyticsAggrSectionScores !== undefined,
    aggAnalyticsSuccess,
    promptRevisionSuccess,
  ].every(Boolean);

  const isLoading =
    getQaAggregationSectionScore.isLoading ||
    getQaAggregationQuestionScore.isLoading;
  const noDataFound = allSuccess && formattedRows.length === 0;

  return (
    <PromptSection
      data={formattedRows}
      isLoading={isLoading}
      noDataFound={noDataFound}
      title="Evaluation Score"
    />
  );
};

export const getTotals = (
  rows: Row[],
): {
  softSkillsOverall: number;
  complianceOverall: number;
  productOverall: number;
  processOverall: number;
} => {
  const {softskillsOverall, complianceOverall, productOverall, processOverall} =
    rows.reduce(
      (
        acc,
        {
          softSkillsTotalScore,
          complianceTotalScore,
          productTotalScore,
          processTotalScore,
        },
      ) => ({
        softskillsOverall:
          acc.softskillsOverall +
          (typeof softSkillsTotalScore !== 'number' ? 0 : softSkillsTotalScore),
        complianceOverall:
          acc.complianceOverall +
          (typeof complianceTotalScore !== 'number' ? 0 : complianceTotalScore),
        productOverall:
          acc.productOverall +
          (typeof productTotalScore !== 'number' ? 0 : productTotalScore),
        processOverall:
          acc.processOverall +
          (typeof processTotalScore !== 'number' ? 0 : processTotalScore),
      }),
      {
        softskillsOverall: 0,
        complianceOverall: 0,
        productOverall: 0,
        processOverall: 0,
      },
    );
  const count = rows.length;
  return {
    softSkillsOverall: softskillsOverall / count,
    complianceOverall: complianceOverall / count,
    productOverall: productOverall / count,
    processOverall: processOverall / count,
  };
};
