/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {useColorScheme} from '@mui/material/styles';
import {List as ListIcon} from '@phosphor-icons/react/dist/ssr/List';
import * as React from 'react';

import {Link} from 'react-router-dom';
import {SideNavMobile} from './side-nav-mobile';
import {UserPopover} from './user-popover';
import {usePopover} from '../../hooks/use-popover';
import {useTrusstGPTState} from '../../providers/AppProvider';
import type {NavItemConfig} from '../../types/nav';

interface MainNavProps {
  mainItems?: NavItemConfig[];
  secondaryItems?: NavItemConfig[];
  compact: boolean;
  setCompact: (compact: boolean) => void;
}

export function TopNav({
  mainItems,
  secondaryItems,
  compact,
  setCompact,
}: MainNavProps): React.JSX.Element {
  const [openNav, setOpenNav] = React.useState<boolean>(false);
  const {user} = useTrusstGPTState();
  const {mode, setMode} = useColorScheme();

  const handleLightDarkModeToggle = () => {
    switch (mode) {
      case 'light':
        setMode('dark');
        break;
      case 'dark':
      case 'system':
        setMode('light');
        break;
    }
  };

  const toggleCompact = (): void => {
    setCompact(!compact);
  };

  const LightDarkModeIcon = mode === 'light' ? LightModeIcon : DarkModeIcon;

  return (
    <React.Fragment>
      <Box
        component="header"
        sx={{
          '--MainNav-background': 'var(--mui-palette-background-default)',
          '--MainNav-divider': 'var(--mui-palette-divider)',
          bgcolor: 'var(--MainNav-background)',
          left: 0,
          position: 'sticky',
          pt: {lg: 'var(--Layout-gap)'},
          top: 0,
          width: '100%',
          zIndex: 'var(--MainNav-zIndex)',
        }}
      >
        <Box
          sx={{
            borderBottom: '1px solid var(--MainNav-divider)',
            display: 'flex',
            flex: '1 1 auto',
            minHeight: 'var(--MainNav-height)',
            px: {xs: 2, lg: 3},
            py: 1,
          }}
        >
          <Box sx={{display: {xs: 'none', lg: 'block'}, ml: -2}}>
            <IconButton aria-label="close" onClick={toggleCompact}>
              <MenuOpenIcon sx={compact ? {transform: 'scaleX(-1)'} : {}} />
            </IconButton>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{alignItems: 'center', flex: '1 1 auto'}}
          >
            <IconButton
              onClick={(): void => {
                setOpenNav(true);
              }}
              sx={{display: {lg: 'none'}}}
            >
              <ListIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center',
              flex: '1 1 auto',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              aria-label="light/dark mode"
              onClick={handleLightDarkModeToggle}
              title={'Light/Dark'}
            >
              <LightDarkModeIcon
                sx={{color: 'var(--mui-palette-primary-main)'}}
              />
            </IconButton>
            <Link to="/calibration" title="Calibration">
              <IconButton aria-label="calibration">
                <TuneIcon sx={{color: 'var(--mui-palette-primary-main)'}} />
              </IconButton>
            </Link>
            <Link to="/settings" title={'Settings'}>
              <IconButton aria-label="settings">
                <SettingsIcon sx={{color: 'var(--mui-palette-primary-main)'}} />
              </IconButton>
            </Link>
            <UserButton handleSignOut={user?.signOut || (() => {})} />
          </Stack>
        </Box>
      </Box>
      <SideNavMobile
        mainItems={mainItems}
        secondaryItems={secondaryItems}
        onClose={() => {
          setOpenNav(false);
        }}
        open={openNav}
      />
    </React.Fragment>
  );
}

type UserButtonProps = {
  handleSignOut: () => void;
};
function UserButton({handleSignOut}: UserButtonProps): React.JSX.Element {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <React.Fragment>
      <Box
        component="button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          p: 0,
          ml: 0.75,
        }}
      >
        <Avatar
          sx={{
            bgcolor: 'var(--mui-palette-primary-main)',
            color: 'var(--mui-palette-primary-contrastText)',
            width: 34,
            height: 34,
          }}
        />
      </Box>
      <UserPopover
        handleSignOut={handleSignOut}
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </React.Fragment>
  );
}
