/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  Contact,
  ContactDetailsFilter,
} from '@agent-assist/api-typescript-react-query-hooks';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import {Box, Button, Card, CardContent, Paper, Stack} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {RotateCw} from 'lucide-react';
import {type ReactNode, useMemo, useState} from 'react';

import {cellRenderers, type ColumnSetting, defaultRenderer} from './columns';
import {FilterOptionsComplete, type OnFilter} from './FilterOptions';
import {EmptyResult} from '../../components/EmptyResult';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import {LegacyButton} from '../../components/ui/legacy-button';
import {useTableEditColumnsModal} from '../../hooks/modal/table-edit-columns-modal';

interface ViewListProps {
  activeFilters?: ContactDetailsFilter[];
  columns: ColumnSetting[];
  contactImportId: string;
  isLoading: boolean;
  items: Contact[];
  onFilter?: OnFilter;
  pagination: ReactNode;
  refetch?: Function;
  search: string;
  tableIndex: number;
}

export const ViewList = ({
  activeFilters,
  columns,
  contactImportId,
  isLoading,
  items,
  onFilter,
  pagination,
  refetch,
  // search, // TODO: Review and reimplement as pagecontainer action url?
  tableIndex,
}: ViewListProps) => {
  if (!items) return <div>No items</div>;

  const [currentColumns, setCurrentColumns] = useState(columns);
  const activeColumns = useMemo(
    () => currentColumns.filter(({isVisible}) => isVisible),
    [currentColumns],
  );
  const tableEditColumns = useTableEditColumnsModal({
    onConfirm: (newColumns) => setCurrentColumns(newColumns),
    columns: activeColumns,
  });

  const baseUrl = `/contacts/${contactImportId}`;
  const cellRenderersMemoized = cellRenderers(baseUrl);
  const renderCell = (key: string, display: string) => {
    const renderer = cellRenderersMemoized.get(key) ?? defaultRenderer;
    return renderer(display);
  };

  const getCellClass = (key: string) => {
    const classes = new Map([['initialDirection', 'text-center']]);
    return classes.get(key);
  };

  const [activeFilterColumn, setActiveFilterColumn] = useState<string>(
    columns.at(0)?.key!,
  );

  return (
    <>
      {activeFilterColumn && activeFilters && onFilter && (
        <Card>
          <CardContent>
            <FilterOptionsComplete
              contactImportId={contactImportId}
              availableFilters={columns}
              onFilter={onFilter}
              activeFilters={activeFilters}
            />
          </CardContent>
        </Card>
      )}
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Button onClick={() => tableEditColumns.show()}>
                  <ViewWeekIcon />
                </Button>
              </TableCell>
              {activeColumns.map(({key, label}) => {
                return (
                  <TableCell key={key}>
                    <Stack
                      direction="row"
                      sx={{alignItems: 'center'}}
                      spacing={0.5}
                    >
                      <span style={{whiteSpace: 'nowrap'}}>{label}</span>
                      <Button onClick={() => setActiveFilterColumn(key)}>
                        <FilterListIcon />
                      </Button>
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableCell colSpan={items.length}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={2}
                >
                  <LoadingSpinner />
                </Box>
              </TableCell>
            </TableBody>
          ) : (
            <TableBody>
              {items.map((contact: Contact, index) => {
                return (
                  <TableRow key={contact.contactId}>
                    <TableCell className="text-center">
                      {index + tableIndex}
                    </TableCell>
                    {activeColumns.map(({key}) => {
                      const cellString = (contact as any)[key];
                      const cellDisplay = renderCell(key, cellString);
                      return (
                        <TableCell
                          key={`row-${index}-${key}`}
                          className={getCellClass(key)}
                        >
                          {cellDisplay}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack direction="row" spacing={2}>
          {pagination}
          {refetch && (
            <LegacyButton
              size={'icon'}
              variant={'ghost'}
              onClick={() => refetch()}
              disabled={isLoading}
            >
              <RotateCw />
            </LegacyButton>
          )}
        </Stack>
      </Box>

      {items.length === 0 ? (
        <div className={'flex flex-1 w-full items-center justify-center py-10'}>
          {isLoading ? null : (
            <EmptyResult text="No imported contacts to display" />
          )}
        </div>
      ) : null}

      <div>{tableEditColumns.modal}</div>
    </>
  );
};
