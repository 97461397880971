/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Box from '@mui/material/Box/Box';
import {DataGridProps} from '@mui/x-data-grid';
import {DataGrid} from '@mui/x-data-grid/DataGrid/DataGrid';

export const MuiDataGrid = ({...props}: DataGridProps) => {
  return (
    <Box style={{height: 400, width: '100%'}}>
      <DataGrid
        paginationMode="server"
        pageSizeOptions={[5, 10, 25, 50, 100]}
        disableColumnFilter={true}
        {...props}
      />
    </Box>
  );
};
