/* tslint:disable */
/* eslint-disable */
/**
 * AgentAssistService
 * Agent Assist API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromptGroups } from './PromptGroups';
import {
    PromptGroupsFromJSON,
    PromptGroupsFromJSONTyped,
    PromptGroupsToJSON,
} from './PromptGroups';

/**
 * 
 * @export
 * @interface CreatePromptRevisionRequestContent
 */
export interface CreatePromptRevisionRequestContent {
    /**
     * Fixed Prompt Hash Key
     * @type {string}
     * @memberof CreatePromptRevisionRequestContent
     */
    promptHashKey: string;
    /**
     * Name of the prompt revision
     * @type {string}
     * @memberof CreatePromptRevisionRequestContent
     */
    name: string;
    /**
     * 
     * @type {PromptGroups}
     * @memberof CreatePromptRevisionRequestContent
     */
    promptGroups: PromptGroups;
}


/**
 * Check if a given object implements the CreatePromptRevisionRequestContent interface.
 */
export function instanceOfCreatePromptRevisionRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "promptHashKey" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "promptGroups" in value;

    return isInstance;
}

export function CreatePromptRevisionRequestContentFromJSON(json: any): CreatePromptRevisionRequestContent {
    return CreatePromptRevisionRequestContentFromJSONTyped(json, false);
}

export function CreatePromptRevisionRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePromptRevisionRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promptHashKey': json['promptHashKey'],
        'name': json['name'],
        'promptGroups': PromptGroupsFromJSON(json['promptGroups']),
    };
}

export function CreatePromptRevisionRequestContentToJSON(value?: CreatePromptRevisionRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promptHashKey': value.promptHashKey,
        'name': value.name,
        'promptGroups': PromptGroupsToJSON(value.promptGroups),
    };
}

