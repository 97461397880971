/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {Card, CardContent} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import {OrganisationDetails} from './OrganisationDetails';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import {PageContainer} from '../../components/ui/page';

export const LoaderWithMessage = ({message}: {message?: string}) => {
  return (
    <div className="flex items-center gap-x-4 flex-row w-full justify-between">
      {message && <div>{message}</div>}
      <LoadingSpinner size={'lg'} />
    </div>
  );
};

export const Settings = () => {
  return (
    <PageContainer title="Settings">
      <Card>
        <CardHeader title="My Organisation" />
        <CardContent>
          <OrganisationDetails />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Settings;
