/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactDetailsFilter,
  useGetBatchContactImport,
  useGetPromptRevision,
  useGetPublishedPromptRevision,
} from '@agent-assist/api-typescript-react-query-hooks';
import {Box, Card, CardContent, Modal} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {columns} from './contact-import/columns';
import {
  FilterOptionsComplete,
  type OnFilterParams,
} from './contact-import/FilterOptions';
import {updateFilters} from './contact-import/filters';
import ContactViewPage from './ContactViewPage';
import {LoadingSpinner} from '../components/LoadingSpinner';
import ExpandableRowTable from '../components/QAAnalytics/QAExpandableDataGrid-datatables';
import {
  ContactImportIds,
  SelectContactImport,
} from '../components/SelectContactImport/SelectContactImport';
import {PageContainer} from '../components/ui/page';
import {
  setPublishedPromptRevision,
  usePromptDispatch,
} from '../providers/PromptProvider';
import {useContactImportsStore} from '../stores/contactImportsStore';

const availableFilters = columns.filter(({key}) =>
  [
    'categorypd',
    'sub-Categorypd',
    'sub-Sub-Categorypd',
    'queueIds',
    'initialDirection',
  ].includes(key),
);

export const CalibrationPage = () => {
  const contactImports = useContactImportsStore(
    (state) => state.contactImports,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentContactId, setCurrentContactId] = useState<string>();
  const [currentContactImportId, setCurrentContactImportId] =
    useState<string>();

  useEffect(() => {
    if (!currentContactImportId && contactImports.length) {
      setCurrentContactImportId(contactImports[0].contactImportId);
    }
  }, [contactImports, currentContactImportId]);

  useEffect(() => {
    setCurrentContactId(searchParams.get('contactId') || undefined);
    const paramContactImportId = searchParams.get('contactImportId');
    if (
      paramContactImportId &&
      currentContactImportId !== paramContactImportId
    ) {
      setCurrentContactImportId(paramContactImportId);
    }
  }, [searchParams]);

  const [search, setSearch] = useSearchParams();
  const [filters, setFilters] = useState<ContactDetailsFilter[]>([]);

  const onFilter = (paramOrParams: OnFilterParams) => {
    const newFilters = updateFilters([search, setSearch], paramOrParams);
    setFilters(newFilters);
  };

  const openContactModal = (contactId: string) => {
    if (currentContactImportId) {
      searchParams.set('contactId', contactId);
      searchParams.set('contactImportId', currentContactImportId);
      setSearchParams(searchParams);
    }
  };

  const closeContactModal = () => {
    searchParams.delete('contactId');
    searchParams.delete('contactImportId');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeContactModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeContactModal]);

  if (!currentContactImportId) {
    return (
      <Box
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  const handleContactImportIdChanged = (contactImport: ContactImportIds) => {
    setCurrentContactImportId(contactImport.contactImportId);
  };

  const actions = (
    <SelectContactImport
      handleContactImportIdChanged={handleContactImportIdChanged}
      initialValue={currentContactImportId}
    />
  );

  return (
    <PageContainer title="Calibration" actions={actions}>
      <Card>
        <CardContent>
          <FilterOptionsComplete
            availableFilters={availableFilters}
            contactImportId={currentContactImportId}
            onFilter={currentContactId ? () => {} : onFilter}
            activeFilters={filters}
          />
        </CardContent>
      </Card>
      <CustomExpandableRow
        contactImportId={currentContactImportId}
        filters={filters}
        handleContactClick={openContactModal}
      />
      <Modal open={!!currentContactId} onClose={closeContactModal}>
        <Box
          component="main"
          sx={{
            '--Content-paddingX': 'calc(4 * var(--mui-spacing))',
            '--Content-paddingY': {
              xs: 'calc(4 * var(--mui-spacing))',
              lg: '64px',
            },
            '--Content-padding':
              'var(--Content-paddingY) var(--Content-paddingX)',
            '--Content-width': '100%',
            maxWidth: 'var(--maxWidth-xl)',
            width: 'var(--Content-width)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Card
            sx={{
              p: 'var(--Content-padding)',
              maxHeight: 'calc(100vh - calc(2 * var(--Content-paddingX)))',
              overflowY: 'auto',
              minHeight: 'calc(100vh - calc(2 * var(--Content-paddingX)))',
            }}
          >
            <ContactViewPage
              contactImportId={currentContactImportId}
              contactId={currentContactId}
              calibrationMode={true}
              onCloseCalibrationModal={closeContactModal}
            />
          </Card>
        </Box>
      </Modal>
    </PageContainer>
  );
};

const CustomExpandableRow = ({
  contactImportId,
  filters,
  handleContactClick,
}: {
  contactImportId: string;
  filters: ContactDetailsFilter[];
  handleContactClick: (contactId: string) => void;
}) => {
  const contactImportJob = useGetBatchContactImport(
    {contactImportId},
    {
      enabled: !!contactImportId,
    },
  );
  const dispatch = usePromptDispatch();

  const promptRevision = contactImportJob.data?.promptRevisionId
    ? useGetPromptRevision({
        promptRevisionId: contactImportJob.data.promptRevisionId,
      })
    : useGetPublishedPromptRevision();

  useEffect(() => {
    if (promptRevision.data) {
      setPublishedPromptRevision(dispatch, promptRevision.data);
    }
  }, [promptRevision.data]);

  return (
    <ExpandableRowTable
      handleContactClick={handleContactClick}
      contactImportId={contactImportId}
      filters={filters}
    />
  );
};
