/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import CallIcon from '@mui/icons-material/Call';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {Button, Chip, Tooltip, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography/Typography';
import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import TrusstGPTDialog from '../../components/MaterialUI/Dialog';
import {TabItem, TrusstGPTTabs} from '../../components/MaterialUI/Tabs';
import {AgentConfigurationsForm} from '../../components/TrusstedAgent/AgentConfigurations/AgentConfigurations';
import AgentTools from '../../components/TrusstedAgent/AgentTools/agentTools';
import ChatTrusstedAgent, {
  EVENT_NAMES,
} from '../../components/TrusstedAgent/ChatAgent/ChatTrusstedAgent';
import {AgentFAQForm} from '../../components/TrusstedAgent/FAQConfigurations/FAQForm';
import {AgentInput} from '../../components/TrusstedAgent/Types/types';
import {PageContainer} from '../../components/ui/page';

import {
  APIParams,
  METHODS,
  useTrusstedAgent,
} from '../../hooks/trusstedAgent/useTrusstedAgent';
import {RuntimeConfigContext} from '../../providers/RuntimeContextProvider';
import {
  discardAgentChanges,
  setAgent,
  useTrusstedAgentDispatch,
  useTrusstedAgentState,
} from '../../providers/TrusstedAgentProvider';

export const PublishedChip = () => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      label="Published"
      sx={{
        '& .MuiBadge-badge': {
          fontSize: 'bold',
          color: theme.palette.primary.dark,
          height: '20px',
          minWidth: '65px',
        },
      }}
    />
  );
};

const AgentTitle = ({
  agent,
  cloneAgent,
}: {
  agent?: AgentInput;
  cloneAgent: (agentId: string) => void;
}) => {
  const handleCloneAgent = () => {
    if (agent?.agentId) {
      cloneAgent(agent.agentId);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid>
        {agent?.published ? (
          <Tooltip
            placement="right-end"
            title={agent?.published ? 'Published Agent' : ''}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography variant="h5" noWrap>
                {agent?.name ?? 'Create Agent'}
              </Typography>
              <HelpOutlineIcon sx={{ml: 1, fontSize: 18}} />
            </div>
          </Tooltip>
        ) : (
          <Typography variant="h5" noWrap>
            {agent?.name ?? 'Create Agent'}
          </Typography>
        )}
      </Grid>
      <Grid container spacing={2} alignItems="center">
        {agent?.agentId && (
          <Grid>
            <Button onClick={handleCloneAgent} variant="outlined">
              Clone
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const ViewAgent = () => {
  const [open, setOpen] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const {agentId} = useParams();
  const dispatch = useTrusstedAgentDispatch();
  const navigate = useNavigate();
  const {modifiedAgent} = useTrusstedAgentState();
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [updatingAgent, setUpdatingAgent] = useState(false);
  const isPublished = modifiedAgent?.published;
  const runtimeContext = useContext(RuntimeConfigContext);
  const apiKey = runtimeContext?.trusstedAgentApiKey;
  const apiEndpoint = runtimeContext?.trusstedAgentApiEndpoint;
  const trusstedAgentAPIHook = (params: APIParams) =>
    useTrusstedAgent({
      apiEndpoint: apiEndpoint,
      apiKey: apiKey,
      ...params,
    });
  const updateAgent = async () => {
    setUpdatingAgent(true);
    try {
      const agentToSubmit = {
        ...modifiedAgent,
        tools: modifiedAgent?.tools?.map((tool) =>
          tool.toolId === 'draft' ? {...tool, toolId: undefined} : tool,
        ),
      };
      const result = await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.ManageAgent,
        methodName: METHODS.POST,
        params: {agent: agentToSubmit},
        queryParams: {},
      });
      setAgent(dispatch, result.agent);
      if (!modifiedAgent?.agentId) {
        navigate(`/agents/edit/${result.agent.agentId}`);
      }
    } catch (error) {
      console.error('Error fetching agent:', error);
    } finally {
      setUpdatingAgent(false);
    }
  };

  const discardChanges = () => {
    discardAgentChanges(dispatch);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const cloneAgent = async () => {
    setIsCloning(true);
    try {
      const result = await trusstedAgentAPIHook({
        eventName: EVENT_NAMES.CloneAgent,
        methodName: METHODS.POST,
        params: {agentId},
        queryParams: {},
      });
      setAgent(dispatch, result);
      navigate(`/agents/edit/${result.agentId}`);
    } catch (error) {
      console.error('Error cloning the agent', error);
    } finally {
      setIsCloning(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const tabs: TabItem[] = [
    {
      label: 'Configurations',
      value: '1',
      content: (
        <AgentConfigurationsForm
          updateAgent={updateAgent}
          updatingAgent={updatingAgent}
          discardChanges={handleClickOpen}
        />
      ),
      icon: <SettingsIcon />,
    },
    {
      label: 'Tools',
      value: '2',
      content: (
        <AgentTools
          updateAgent={updateAgent}
          updatingAgent={updatingAgent}
          discardChanges={handleClickOpen}
        />
      ),
      icon: <EditIcon />,
    },
    {
      label: isPublished ? 'Chat Agent' : 'Test Agent',
      value: '3',
      content: <ChatTrusstedAgent />,
      icon: <SupportAgentIcon />,
    },
    {
      label: 'Simulator',
      value: '4',
      content: <div>To be implemented</div>,
      icon: <CallIcon />,
    },
    {
      label: 'FAQ Settings',
      value: '5',
      content: (
        <AgentFAQForm
          updateAgent={updateAgent}
          updatingAgent={updatingAgent}
          discardChanges={handleClickOpen}
        />
      ),
      icon: <QuizIcon />,
    },
  ];

  useEffect(() => {
    if (agentId) {
      const getAgent = async () => {
        setLoadingAgent(true);
        try {
          const queryParams: any = {
            agentId,
          };
          const result = await trusstedAgentAPIHook({
            eventName: EVENT_NAMES.GetAgent,
            methodName: METHODS.GET,
            params: {},
            queryParams,
          });
          setAgent(dispatch, result.agent);
        } catch (error) {
          console.error('Error fetching agent:', error);
        } finally {
          setLoadingAgent(false);
        }
      };
      void getAgent();
    }
  }, [agentId]);

  return loadingAgent || isCloning ? (
    <LoadingSpinner size={'sm'} />
  ) : (
    <PageContainer
      title={
        <AgentTitle
          agent={modifiedAgent}
          cloneAgent={() => void cloneAgent()}
        />
      }
    >
      <TrusstGPTDialog
        open={open}
        handleClose={handleClose}
        onConfirm={discardChanges}
        title="Discard Changes"
        caption="Are you sure you want to discard the changes?"
      />
      <TrusstGPTTabs tabs={tabs} />
    </PageContainer>
  );
};
export default ViewAgent;
