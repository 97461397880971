/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  type GetBatchContactImportResponseContent,
  type GetPromptRevisionResponseContent,
  type ResponseError,
} from '@agent-assist/api-typescript-react-query-hooks';

import {Card, CardContent, Link, Stack} from '@mui/material';
import Grid from '@mui/material/Grid2';
import type {QueryObserverSuccessResult} from '@tanstack/react-query';
import {Link as RouterLink} from 'react-router-dom';

import {type ProgressCounts} from './ViewContactImport';
import ContactImportProgressBar from '../../components/ContactImportProgressBar';
import LegacyHeading from '../../components/Heading';
import StatusIndicator, {
  type StatusTypeEnum,
} from '../../components/StatusIndicator';
import {shortenUuid} from '../../lib/formatString';

interface ViewHeaderProps {
  contactImport: QueryObserverSuccessResult<
    GetBatchContactImportResponseContent,
    ResponseError
  >;
  contactImportId: string;
  liveProgress: ProgressCounts;
  promptRevisionData?: GetPromptRevisionResponseContent;
  showLive: boolean;
}

export const ViewHeader = ({
  contactImport,
  contactImportId,
  liveProgress,
  promptRevisionData,
  showLive,
}: ViewHeaderProps) => {
  const {bucket, createdBy, createdAt, key, status, promptRevisionId} =
    contactImport.data;

  const total = showLive
    ? liveProgress.totalCount
    : contactImport.data.totalCount;
  const processed = showLive
    ? liveProgress.processedCount
    : contactImport.data.processedCount;

  return (
    <Grid
      container
      spacing={4}
      columns={3}
      sx={{display: 'flex', alignItems: 'stretch'}}
    >
      <Grid size={1} sx={{display: 'flex'}}>
        <Card sx={{flex: 1}}>
          <CardContent>
            <Stack spacing={2}>
              <Stack spacing={1}>
                <LegacyHeading h3>Status:</LegacyHeading>
                <StatusIndicator status={status as StatusTypeEnum} />
              </Stack>
              <Stack spacing={1}>
                <LegacyHeading h3>Progress:</LegacyHeading>
                {typeof total === 'number' && typeof processed === 'number' && (
                  <ContactImportProgressBar
                    processedCount={processed}
                    totalCount={total}
                    status={status}
                  />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid size={1} sx={{display: 'flex'}}>
        <Card sx={{flex: 1}}>
          <CardContent>
            <Stack spacing={2}>
              <Stack spacing={1}>
                <LegacyHeading h3>S3 Location:</LegacyHeading>
                <p>{`s3://${bucket}/${key}`}</p>
              </Stack>
              <Stack spacing={1}>
                <LegacyHeading h3>Contact Import ID:</LegacyHeading>
                <p>{shortenUuid(contactImportId)}</p>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid size={1} sx={{display: 'flex'}}>
        <Card sx={{flex: 1}}>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={1}>
                <LegacyHeading h3>Created by:</LegacyHeading>
                <p style={{textTransform: 'capitalize'}}>
                  {createdBy.username}
                </p>
              </Stack>
              <Stack direction="row" spacing={1}>
                <LegacyHeading h3>Created at:</LegacyHeading>
                <p>{new Date(createdAt).toLocaleString()}</p>
              </Stack>
              <Stack spacing={1}>
                <LegacyHeading h3>Insights Prompt:</LegacyHeading>
                <Link
                  to={`/prompts/${promptRevisionId}`}
                  component={RouterLink}
                >
                  {promptRevisionData?.name ?? shortenUuid(promptRevisionId)}
                </Link>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
