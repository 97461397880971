/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import Box from '@mui/material/Box';
import * as React from 'react';

type Color = 'dark' | 'light';

interface LogoProps {
  color?: Color;
  height: number;
  compact?: boolean;
}

export function Logo({
  color = 'dark',
  height,
  compact,
}: LogoProps): React.JSX.Element {
  const url = `/images/trusstai-logo${compact ? '-compact' : ''}-${color}.png`;
  return <Box alt="Logo" component="img" height={height} src={url} />;
}
