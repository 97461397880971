/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import LogoutIcon from '@mui/icons-material/Logout';
import {ListItemIcon, MenuItem} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useTrusstGPTState} from '../../providers/AppProvider';

interface UserPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  handleSignOut: () => void;
  open: boolean;
}

export function UserPopover({
  anchorEl,
  onClose,
  open,
  handleSignOut,
}: UserPopoverProps): React.JSX.Element {
  const {user} = useTrusstGPTState();
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      onClose={onClose}
      open={Boolean(open)}
      slotProps={{paper: {sx: {width: '280px', marginTop: '4px'}}}}
      transformOrigin={{horizontal: 'right', vertical: 'top'}}
    >
      <Box sx={{p: 2, mt: 0.5}}>
        <Typography>
          {user?.familyName} {user?.givenName}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{p: 1}}>
        <MenuItem
          component="div"
          onClick={handleSignOut}
          sx={{justifyContent: 'center', gap: 1}}
        >
          Sign out
          <ListItemIcon>
            <LogoutIcon fontSize={'small'} />
          </ListItemIcon>
        </MenuItem>
      </Box>
    </Popover>
  );
}
