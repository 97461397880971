/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ContactImport,
  useListBatchContactImports,
} from '@agent-assist/api-typescript-react-query-hooks';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Button, Card, Paper, Stack} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {FC, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CreateContactImportModal from '../../components/CreateContactImportModal';
import {
  type LegacyDateRange as DateRange,
  MuiDateRangePicker,
} from '../../components/DateAndTimeRange/DateAndTimeRange';
import {EmptyResult} from '../../components/EmptyResult';
import {LoadingSpinner} from '../../components/LoadingSpinner';
import StatusIndicator, {
  StatusTypeEnum,
} from '../../components/StatusIndicator';
import {PageContainer} from '../../components/ui/page';
import {usePaginatedTableData} from '../../hooks/table/table-hooks';
import {ErrorPage} from '../error/error-page';

const PAGE_SIZE = 15;

interface ContactImportsProps {}

export const ContactImports: FC<ContactImportsProps> = () => {
  const navigate = useNavigate();
  const [isCreatingJob, setIsCreatingJob] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const contactImports = useListBatchContactImports(
    {
      before: dateRange?.to
        ? dateRange.to.getTime() - dateRange.to.getTimezoneOffset() * 60 * 1000
        : undefined,
      after: dateRange?.from
        ? dateRange.from.getTime() -
          dateRange.from.getTimezoneOffset() * 60 * 1000
        : undefined,
      pageSize: PAGE_SIZE,
    },
    {
      retry: false,
    },
  );

  const {items, loading, pagination, refetch, tableIndex} =
    usePaginatedTableData(contactImports, 'imports', {
      pageSize: PAGE_SIZE,
    });

  const isRefetching =
    items.length > 0 && (loading || contactImports.isRefetching);

  const currentDateRange: [Date | null, Date | null] = useMemo(() => {
    return [
      dateRange?.from ? new Date(dateRange?.from) : null,
      dateRange?.to ? new Date(dateRange?.to) : null,
    ];
  }, [dateRange]);

  return (
    <PageContainer
      title="Contact Imports"
      actions={
        <Button
          variant="outlined"
          onClick={() => setIsCreatingJob(true)}
          startIcon={<AddCircleOutlineIcon />}
        >
          New Import
        </Button>
      }
    >
      <CreateContactImportModal
        isOpen={isCreatingJob}
        onClose={() => setIsCreatingJob(false)}
      />
      <Stack spacing={4}>
        <Card sx={{p: 2}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MuiDateRangePicker
                defaultValue={currentDateRange}
                onAccept={([from, to]) =>
                  setDateRange({
                    from: from ?? undefined,
                    to: to ?? undefined,
                  })
                }
              />
              <Box sx={{ml: 2}}>
                {isRefetching ? <LoadingSpinner size={'sm'} /> : null}
              </Box>
            </Box>
            <Box>
              <Stack spacing={1} direction="row">
                <Box>{pagination}</Box>
                <IconButton
                  aria-label="refresh"
                  onClick={refetch}
                  disabled={isRefetching}
                >
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Card>

        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Import ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((contactImport: ContactImport, index) => (
                <TableRow
                  key={contactImport.contactImportId}
                  onClick={() =>
                    navigate(`/contacts/${contactImport.contactImportId}`)
                  }
                  hover
                  sx={{cursor: 'pointer'}}
                >
                  <TableCell>{index + tableIndex}</TableCell>
                  <TableCell>{contactImport.contactImportId}</TableCell>
                  <TableCell>{contactImport.name}</TableCell>
                  <TableCell>
                    <StatusIndicator
                      status={contactImport.status as StatusTypeEnum}
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(contactImport.createdAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {contactImports.isError ? (
          <ErrorPage errors={[contactImports.error]} />
        ) : items.length === 0 ? (
          <div
            className={'flex flex-1 w-full items-center justify-center py-10'}
          >
            {contactImports.status === 'loading' ? (
              <LoadingSpinner />
            ) : (
              <EmptyResult text="No contact imports to display" />
            )}
          </div>
        ) : null}
      </Stack>
    </PageContainer>
  );
};

export default ContactImports;
