/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  AnalyticsData,
  ContactDetailsFilter,
  QAAnalyticsResults,
  SynopsisEvaluationRating,
  useGetQAAnalyticsWithFilters,
} from '@agent-assist/api-typescript-react-query-hooks';
import CommentIcon from '@mui/icons-material/Comment';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables';
import {useEffect, useState} from 'react';
import {usePromptCommentModal} from '../../hooks/modal/prompt-comment-modal';
import {localTimeToDatabaseTime} from '../../lib/formatDate';
import {cleanseFilters} from '../../pages/contact-import/cleanseFilters';
import {LoadingSpinner} from '../LoadingSpinner';

type KeyAny = {[key: string]: any};

export interface Row {
  id: string;
  contactId: string;
  intent: string;
  resolution: string;
  category: string;
  subCategory: string;
  subSubCategory: string;
  softSkillsTotalScore: number | string;
  complianceTotalScore: number | string;
  productTotalScore: number | string;
  processTotalScore: number | string;
  createdAt: string;
}

const headers: {key: string; value: string; redshiftKey: string}[] = [
  {key: 'contactId', value: 'Contact ID', redshiftKey: 'contactId'},
  {key: 'intent', value: 'Intent', redshiftKey: 'intentpd'},
  {key: 'resolution', value: 'Resolution', redshiftKey: 'resolutionpd'},
  {key: 'category', value: 'Primary Category', redshiftKey: 'categorypd'},
  {
    key: 'subCategory',
    value: 'Secondary Category',
    redshiftKey: 'sub-Categorypd',
  },
  {
    key: 'subSubCategory',
    value: 'Tertiary Category',
    redshiftKey: 'sub-Sub-Categorypd',
  },
  {
    key: 'softSkillsTotalScore',
    value: 'Achieved SoftSkills Score(%)',
    redshiftKey: 'softSkillsTotalScore',
  },
  {
    key: 'complianceTotalScore',
    value: 'Achieved Compliance Score(%)',
    redshiftKey: 'complianceTotalScore',
  },
  {
    key: 'productTotalScore',
    value: 'Achieved Product Score(%)',
    redshiftKey: 'productTotalScore',
  },
  {
    key: 'processTotalScore',
    value: 'Achieved Process Score(%)',
    redshiftKey: 'processTotalScore',
  },
  {key: 'createdAt', value: 'Contact Date', redshiftKey: 'createdAt'},
];
const columns: MUIDataTableColumn[] = headers.map((kv) => {
  return {
    name: kv.key,
    label: kv.value,
  };
});

export const PromptRating = (props: {
  promptRating?: SynopsisEvaluationRating;
  promptComment?: string;
  onFeedback: (
    questionId: string,
    rating: SynopsisEvaluationRating,
  ) => Promise<void>;
  onSaveComment: (questionId: string, comment: string) => Promise<void>;
  questionId: string;
  contactImportId?: string;
  disabled: boolean;
}) => {
  const {questionId, promptComment, promptRating, onFeedback, onSaveComment} =
    props;
  const [localRating, setLocalRating] = useState(promptRating);
  const [localComment, setLocalComment] = useState(promptComment);
  const [loadingIcon, setLoadingIcon] =
    useState<SynopsisEvaluationRating | null>();

  const commentConfirmation = usePromptCommentModal({
    onConfirm: () => onSaveComment(questionId, localComment ?? ''),
    onChangeComment: setLocalComment,
    comment: localComment ?? '',
  });

  useEffect(() => {
    if (!props.disabled) {
      setLoadingIcon(null);
    }
  }, [props.disabled]);

  return (
    <div className={'flex'}>
      {commentConfirmation.modal}
      <IconButton
        size="small"
        onClick={() => {
          setLoadingIcon('GOOD');
          void onFeedback(questionId, 'GOOD');
          setLocalRating('GOOD');
        }}
        disabled={props.disabled}
      >
        {loadingIcon === 'GOOD' ? (
          <LoadingSpinner size={'xs'} />
        ) : localRating === 'GOOD' ? (
          <ThumbUpIcon fontSize={'small'} />
        ) : (
          <ThumbUpOffAltIcon fontSize={'small'} />
        )}
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          setLoadingIcon('BAD');
          void onFeedback(questionId, 'BAD');
          setLocalRating('BAD');
        }}
        disabled={props.disabled}
      >
        {loadingIcon === 'BAD' ? (
          <LoadingSpinner size={'xs'} />
        ) : localRating === 'BAD' ? (
          <ThumbDownIcon fontSize={'small'} />
        ) : (
          <ThumbDownOffAltIcon fontSize={'small'} />
        )}
      </IconButton>
      <IconButton
        size={'small'}
        onClick={() => commentConfirmation.show()}
        disabled={props.disabled}
      >
        {localComment?.length ? (
          <MarkUnreadChatAltIcon fontSize={'small'} />
        ) : (
          <CommentIcon fontSize={'small'} />
        )}
      </IconButton>
    </div>
  );
};

const createRowData = (analyticsRows: AnalyticsData[]): Row => {
  const getValue = (key: string): string => {
    const redshiftKey = headers.find(
      (header) => header.key === key,
    )?.redshiftKey;
    const result = analyticsRows.find((row) => row.key === redshiftKey);
    return result?.value?.toString() || '';
  };

  const parseScore = (key: string): string | number => {
    const score = Number(getValue(key));
    return isNaN(score) ? 'N/A' : score;
  };

  const createdAt = getValue('createdAt');
  const formattedDate = createdAt
    ? new Date(createdAt).toISOString().split('T')[0]
    : new Date().toISOString().split('T')[0];

  return {
    id: getValue('contactId'),
    contactId: getValue('contactId'),
    intent: getValue('intent'),
    resolution: getValue('resolution'),
    category: getValue('category'),
    subCategory: getValue('subCategory'),
    subSubCategory: getValue('subSubCategory'),
    softSkillsTotalScore: parseScore('softSkillsTotalScore'),
    complianceTotalScore: parseScore('complianceTotalScore'),
    productTotalScore: parseScore('productTotalScore'),
    processTotalScore: parseScore('processTotalScore'),
    createdAt: formattedDate,
  };
};

export const convertQaAnalyticsToArray = (obj: QAAnalyticsResults): Row[] => {
  return Object.values(obj).map(createRowData);
};

interface ExpandableRowTableProps {
  handleContactClick: (contactId: string) => void;
  contactImportId: string;
  filters: ContactDetailsFilter[];
}

interface QAAnalyticsResultsState {
  data: QAAnalyticsResults;
  count: number;
  rowsPerPage: number;
  page: number;
  nextToken: string | undefined;
  totalPages: number;
}

const ExpandableRowTable = ({
  handleContactClick,
  contactImportId,
  filters,
}: ExpandableRowTableProps) => {
  const [rows, setRows] = useState<KeyAny[]>([]);

  const {mutateAsync, isLoading, isSuccess} = useGetQAAnalyticsWithFilters();

  const initialState: QAAnalyticsResultsState = {
    data: {},
    count: 0,
    rowsPerPage: 10,
    page: 0,
    nextToken: undefined,
    totalPages: 0,
  };

  const [state, setState] = useState<QAAnalyticsResultsState>(initialState);

  const getData = async (
    pageNum: number,
    token?: string,
    rowsPerPage?: number,
  ) => {
    if (!contactImportId) return;

    const {filters: cleansedFilters, from, to} = cleanseFilters(filters);
    try {
      const result = await mutateAsync({
        contactImportId,
        pageSize: rowsPerPage ?? state.rowsPerPage,
        nextToken: token,
        getQAAnalyticsWithFiltersRequestContent: {
          filters: cleansedFilters,
          from: localTimeToDatabaseTime(from),
          to: localTimeToDatabaseTime(to),
        },
      });

      setState((prev) => ({
        ...prev,
        data: result.analytics,
        count: result.totalRecords ?? 0,
        totalPages: result.totalPages ?? 0,
        page: pageNum,
        nextToken: result.nextToken,
        rowsPerPage: rowsPerPage ?? prev.rowsPerPage,
      }));
    } catch (error) {
      console.error('Error fetching analytics:', error);
    }
  };

  useEffect(() => {
    void getData(0);
  }, [contactImportId, filters]);

  // Update rows when data changes
  useEffect(() => {
    if (state.data && Object.keys(state.data).length > 0) {
      const newRows = convertQaAnalyticsToArray(state.data);
      setRows(newRows);
    } else {
      setRows([]);
    }
  }, [state.data]);

  const options: MUIDataTableOptions = {
    filter: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'vertical',
    count: state.count,
    serverSide: true,
    rowsPerPage: state.rowsPerPage,
    rowsPerPageOptions: [10, 20, 50, 100],
    page: state.page,
    onRowClick: (_rowData, rowMeta) =>
      handleContactClick(rows[rowMeta.dataIndex]?.id),
    rowHover: true,
    print: false,
    search: false,
    viewColumns: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          void getData(tableState.page, String(tableState.page));
          break;
        case 'changeRowsPerPage':
          void getData(0, undefined, tableState.rowsPerPage);
          setState((prev) => ({
            ...prev,
            rowsPerPage: tableState.rowsPerPage,
          }));
          break;
        default:
          break;
      }
    },
    tableBodyHeight: '600px',
    tableBodyMaxHeight: 'auto',
    fixedHeader: true,
    setTableProps: () => ({
      'aria-busy': `${isLoading}`,
    }),
  };
  return (
    <Box sx={{width: '100%'}}>
      <div className="relative">
        {isSuccess && !state.data ? (
          <p className="text-xs font-semibold my-10 w-full">
            No Analytics Data has been populated for this Contact Import Job
          </p>
        ) : (
          <>
            <MUIDataTable
              title={'Contacts'}
              data={isLoading && rows.length === 0 ? [{}] : rows}
              columns={columns}
              options={options}
            />
            {isLoading && (
              <div className="absolute top-0 left-0 right-0 z-50 bg-white/50 p-2 flex justify-center">
                <LoadingSpinner size={'lg'} />
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export default ExpandableRowTable;
